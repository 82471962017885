
import OSS from 'ali-oss'
// import eventBus from './eventBus';
class ClientSTS {
    constructor(fileName=null, progressCallback = null) {
        this.credentials = null;
        this.file = null;
        this.extara = null;
        this.fileName = fileName;
        this.progressCallback = progressCallback; // 接收进度回调
    }
    createClient = async () => {
        const client = new OSS({
            bucket: "bfine-official-website",
            region: "oss-cn-hangzhou",
            accessKeyId: this.credentials.AccessKeyId,
            accessKeySecret: this.credentials.AccessKeySecret,
            stsToken: this.credentials.SecurityToken,
        });
        // 分离文件名和扩展名
        let ext = this.file.name.substring(this.file.name.lastIndexOf('.'));
        // 执行上传，并传入 onProgress 选项
        const res=await client.multipartUpload(this.extara + ext, this.file, {
            progress: this.onProgress
        });
        if(res.res.status===200&&res.etag){
            return true
        }
        else{
            return false
        }
        
    }
    isCredentialsExpired() {
        if (!this.credentials) {
            return true;
        }
        const expireDate = new Date(this.credentials.Expiration);
        const now = new Date();
        // 如果有效期不足30分钟，视为过期。
        return expireDate.getTime() - now.getTime() <= 1800000;
    }
    // 上传的进度回调函数
    onProgress = (progress) => {
        if (this.progressCallback) {
            const percent = Math.floor(progress * 100);
            this.progressCallback(this.fileName, percent); // 触发外部回调
        }

    };
}
export default ClientSTS

