<template>
  <div id="app">
    <div class="bimtitle">
      <el-image :src="Navigatelogourl" fit="fill" class="logo" />
      <label class="filename">{{ filename }}
        <!-- &nbsp;&nbsp;V{{ file_version }} -->
      </label>
      <el-image :src="Closeurl_twice" fit="fill" @click="CloseBimtitle" class="title_icon_style"></el-image>
    </div>
    <iframe :src="otherProjectUrl" class="webview"></iframe>
  </div>
</template>
<script>
export default {
  name: 'WebView',
  props: {
    msg: String
  },

}
</script>
<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
const Navigatelogourl = require('@/img/72-2.png')
const Closeurl_twice = require('@/img/Close_twice.svg')
// const route = useRoute();
const router = useRouter();
const otherProjectUrl = ref('');
const filename = ref();
// const file_version = ref();
onMounted(() => {
  const projectinfo= JSON.parse(sessionStorage.getItem('projectinfo'))
  filename.value = projectinfo.CivilName
  // file_version.value = fileinfo.FileVersion
  // const data = route.params;
  // if (data) {
  //   filename.value = data.fileName;
  //   file_version.value = data.fileversion;
  //   const encodedData = encodeURIComponent(JSON.stringify(data));
  //   const baseUrl = process.env.VUE_APP_BASE_URL;
  //   // otherProjectUrl.value = `${baseUrl}webviewer/?data=${encodedData}`;
  otherProjectUrl.value = process.env.VUE_APP_WEBVIEWER_URL;
  // }
})
const CloseBimtitle = () => {
  router.back();//浏览器历史回退
}
</script>
