<template>
    <el-empty description="未授权,请联系管理员授权" class="empty"/>
</template>

<script>
import "../css/quality.css"
export default {
    name: 'QualityA',
    props: {
        msg: String
    },
}
</script>

<style></style>
<!-- <template>
    <div ref="sceneContainer" class="scene-container"></div>
  </template>
  
  <script>
  import { onMounted, onBeforeUnmount, ref } from 'vue';
  import * as THREE from 'three';
  import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
  
  export default {
    name: 'ModelViewer',
    setup() {
      const sceneContainer = ref(null); // 引用容器
      let scene = null;
      let camera = null;
      let renderer = null;
      let model = null;
  
      // 初始化Three.js场景
      const initScene = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
  
        // 创建场景
        scene = new THREE.Scene();
  
        // 创建相机
        camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        camera.position.z = 5;
  
        // 创建渲染器
        renderer = new THREE.WebGLRenderer();
        renderer.setSize(width, height);
        sceneContainer.value.appendChild(renderer.domElement);
  
        // 添加光源
        const ambientLight = new THREE.AmbientLight(0x404040); // 环境光
        scene.add(ambientLight);
        const pointLight = new THREE.PointLight(0xffffff, 1, 100);
        pointLight.position.set(10, 10, 10);
        scene.add(pointLight);
      };
  
      // 加载.glb模型
      const loadModel = () => {
        const loader = new GLTFLoader();
        loader.load(
          'https://bim.bfine-tech.com/uploads/Test123/2.glb', // 模型的 URL 地址
          (gltf) => {
            model = gltf.scene;
            scene.add(model);
            model.scale.set(0.1, 0.1, 0.1); // 缩放模型
            animate();
          },
          (xhr) => {
            console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
          },
          (error) => {
            console.error('Error loading GLTF model:', error);
          }
        );
      };
  
      // 动画更新函数
      const animate = () => {
        requestAnimationFrame(animate);
        if (model) {
          model.rotation.y += 0.01; // 旋转模型
        }
        renderer.render(scene, camera);
      };
  
      // 清理资源
      onBeforeUnmount(() => {
        if (renderer) {
          renderer.dispose();
        }
      });
  
      // 组件挂载时初始化场景并加载模型
      onMounted(() => {
        initScene();
        loadModel();
      });
  
      return {
        sceneContainer,
      };
    },
  };
  </script>
  
  <style scoped>
  .scene-container {
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
  }
  </style> -->
  
