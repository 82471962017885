<template>
    <div id="base" class="">



        <!-- Unnamed (图片) -->


        <!-- Unnamed (组合) -->
        <div id="u4" class="ax_default" data-left="283" data-top="19" data-width="612" data-height="413"
            layer-opacity="1">



            <!-- Unnamed (表格)消息列表的表格 -->
            <div id="u9" class="ax_default">
                <div id="u8_text" class="text ">
                    <p><span>消息列表</span></p>
                </div>
                <el-table :data="notice_tableData" class="notice_table" @row-dblclick="notice_dbclick">
                    <el-table-column prop="InformType" label="消息类型" width="110" align="center">
                        <template #default="{ row }">
                            <span>
                                {{ informtype(row.InformType) }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="Content" label="消息内容" align="center">
                        <template #default="{ row }">
                            <div class="table_lable">
                                <label>
                                    {{ row.Content }}
                                </label>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div id="u29" class="ax_default" data-left="912" data-top="19" data-width="612" data-height="413"
                layer-opacity="1">

                <!-- Unnamed (形状) -->


                <!-- Unnamed (矩形) -->
                <div id="u33" class="ax_default label">
                    <div id="u33_div" class=""></div>
                    <div id="u33_text" class="text ">
                        <p><span>项目任务统计</span></p>
                    </div>
                </div>

                <!-- Unnamed (矩形) -->
                <div id="u34" class="ax_default box_2">
                    <div id="u34_div" class=""></div>
                    <div id="u34_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- Unnamed (矩形) -->
                <div id="u35" class="ax_default box_2">
                    <div id="u35_div" class=""></div>
                    <div id="u35_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- Unnamed (矩形) -->
                <div id="u36" class="ax_default box_2">
                    <div id="u36_div" class=""></div>
                    <div id="u36_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- Unnamed (矩形) -->
                <div id="u37" class="ax_default box_2">
                    <div id="u37_div" class=""></div>
                    <div id="u37_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- Unnamed (矩形) -->
                <div id="u38" class="ax_default _三级标题">
                    <div id="u38_div" class=""></div>
                    <div id="u38_text" class="text ">
                        <p><span>待完成任务</span></p>
                    </div>
                </div>

                <!-- Unnamed (椭圆) -->
                <div id="u39" class="ax_default ellipse">
                    <img id="u39_img" class="img " src="../images/页面_1/u39.svg" />
                    <div id="u39_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- Unnamed (形状) -->
                <div id="u40" class="ax_default line">
                    <img id="u40_img" class="img " src="../images/页面_1/u40.svg" />
                    <div id="u40_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- Unnamed (形状) -->
                <div id="u41" class="ax_default line">
                    <img id="u41_img" class="img " src="../images/页面_1/u41.svg" />
                    <div id="u41_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- Unnamed (形状) -->
                <div id="u42" class="ax_default line">
                    <img id="u42_img" class="img " src="../images/页面_1/u42.svg" />
                    <div id="u42_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- Unnamed (形状) -->
                <div id="u43" class="ax_default line">
                    <img id="u43_img" class="img " src="../images/页面_1/u43.svg" />
                    <div id="u43_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- 50 (矩形) -->
                <div id="u44" class="ax_default _二级标题" data-label="50">
                    <div id="u44_div" class=""></div>
                    <div id="u44_text" class="text ">
                        {{ finishedNum }}件
                    </div>
                </div>

                <!-- Unnamed (矩形) -->
                <!-- <div id="u45" class="ax_default label">
                <div id="u45_div" class=""></div>
                <div id="u45_text" class="text ">
                    <p><span>件</span></p>
                </div>
            </div> -->

                <!-- Unnamed (矩形) -->
                <div id="u46" class="ax_default _三级标题">
                    <div id="u46_div" class=""></div>
                    <div id="u46_text" class="text ">
                        <p><span>已完成任务</span></p>
                    </div>
                </div>

                <!-- Unnamed (椭圆) -->
                <div id="u47" class="ax_default ellipse">
                    <img id="u47_img" class="img " src="../images/页面_1/u47.svg" />
                    <div id="u47_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- Unnamed (形状) -->
                <div id="u48" class="ax_default line">
                    <img id="u48_img" class="img " src="../images/页面_1/u48.svg" />
                    <div id="u48_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- 50 (矩形) -->
                <div id="u49" class="ax_default _二级标题" data-label="50">
                    <div id="u49_div" class=""></div>
                    <div id="u49_text" class="text ">
                        <span>{{ unfinishedTaskNum }}件</span>
                    </div>
                </div>

                <!-- Unnamed (矩形) -->
                <!-- <div id="u50" class="ax_default label">
                <div id="u50_div" class=""></div>
                <div id="u50_text" class="text ">
                    <p><span>件</span></p>
                </div>
            </div> -->

                <!-- Unnamed (矩形) -->
                <div id="u51" class="ax_default _三级标题">
                    <div id="u51_div" class=""></div>
                    <div id="u51_text" class="text ">
                        <p><span>完成比例</span></p>
                    </div>
                </div>

                <!-- 50 (矩形) -->
                <div id="u52" class="ax_default _二级标题" data-label="50">
                    <div id="u52_div" class=""></div>
                    <div id="u52_text" class="text ">
                        {{ TotalNum != 0 ? (finishedNum / TotalNum * 100).toFixed(1) : 0 }}%
                    </div>
                </div>

                <!-- Unnamed (矩形) -->
                <!-- <div id="u53" class="ax_default label">
                <div id="u53_div" class=""></div>
                <div id="u53_text" class="text ">
                    <p><span>%</span></p>
                </div>
            </div> -->

                <!-- Unnamed (矩形) -->
                <div id="u54" class="ax_default _三级标题">
                    <div id="u54_div" class=""></div>
                    <div id="u54_text" class="text ">
                        <p><span>项目总任务</span></p>
                    </div>
                </div>

                <!-- Unnamed (椭圆) -->
                <div id="u55" class="ax_default ellipse">
                    <img id="u55_img" class="img " src="../images/页面_1/u55.svg" />
                    <div id="u55_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- 50 (矩形) -->
                <div id="u56" class="ax_default _二级标题" data-label="50">
                    <div id="u56_div" class=""></div>
                    <div id="u56_text" class="text ">
                        {{ TotalNum }}件
                    </div>
                </div>

                <!-- Unnamed (矩形) -->
                <!-- <div id="u57" class="ax_default label">
                <div id="u57_div" class=""></div>
                <div id="u57_text" class="text ">
                    <p><span>件</span></p>
                </div>
            </div> -->

                <!-- Unnamed (形状) -->
                <div id="u58" class="ax_default line">
                    <img id="u58_img" class="img " src="../images/页面_1/u58.svg" />
                    <div id="u58_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- Unnamed (形状) -->
                <div id="u59" class="ax_default line">
                    <img id="u59_img" class="img " src="../images/页面_1/u59.svg" />
                    <div id="u59_text" class="text " style="display:none; visibility: hidden">
                        <p></p>
                    </div>
                </div>

                <!-- Unnamed (组合) -->
                <div id="u60" class="ax_default" data-left="1287" data-top="291" data-width="72" data-height="72"
                    layer-opacity="1">

                    <!-- Unnamed (椭圆) -->
                    <div id="u61" class="ax_default ellipse">
                        <img id="u61_img" class="img " src="../images/页面_1/u61.svg" />
                        <div id="u61_text" class="text " style="display:none; visibility: hidden">
                            <p></p>
                        </div>
                    </div>

                    <!-- Unnamed (饼图) -->
                    <div id="u62" class="ax_default ellipse">
                        <img id="u62_img" class="img " src="../images/页面_1/u62.svg" />
                        <div id="u62_text" class="text " style="display:none; visibility: hidden">
                            <p></p>
                        </div>
                    </div>

                    <!-- Unnamed (椭圆) -->
                    <div id="u63" class="ax_default ellipse">
                        <img id="u63_img" class="img " src="../images/页面_1/u63.svg" />
                        <div id="u63_text" class="text ">
                            {{ TotalNum != 0 ? (finishedNum / TotalNum * 100).toFixed(1) : 0 }}%
                        </div>
                    </div>
                </div>
            </div>
            <div id="u2" class="ax_default _图片">
                <el-calendar v-model="calendarvalue" class="calendar" />
            </div>
        </div>



        <!-- Unnamed (组合) -->



        <div class="u1">
            <!-- Unnamed (表格) -->
            <div id="u69" class="ax_default">
                <div id="u69_text" class="text ">
                    <p><span>最新文件列表</span></p>
                </div>
                <el-table :data="file_tableData" class="file_table" @row-dblclick="file_rowdblclick">
                    <el-table-column prop="FileName" label="文件名称" :width="getDynamicWidth(480)" align="center"
                        show-overflow-tooltip> <template #default="{ row }">
                            <div class="table_lable">
                                <label>
                                    {{ row.FileName }}
                                </label>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="FileType" label="类型" :width="getDynamicWidth(100)" align="center">
                        <template #default="{ row }">
                            <span>
                                {{ getFileTypeByValue(row.FileType) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="日期" align="center">
                        <template #default="{ row }">
                            <span>
                                {{ formatDate(row.UploadTime) }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>


            </div>

            <!-- Unnamed (表格) -->
            <div id="u91" class="ax_default">
                <div id="u69_text" class="text ">
                    <p><span>任务列表</span></p>
                </div>
                <el-table :data="renwu_tableData" class="renwu_table" @row-dblclick="renwu_dbclick">
                    <el-table-column prop="TaskCode" label="任务编号" :width="getDynamicWidth(280)" align="center" />
                    <el-table-column prop="TaskName" label="任务名称" :width="getDynamicWidth(780)" align="center"
                        show-overflow-tooltip><template #default="{ row }">
                            <div class="table_lable">
                                <label>
                                    {{ row.TaskName }}
                                </label>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template #default="{ row }">
                            <span>
                                {{ row.status ? "已完成" : "进行中" }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>
        </div>

    </div>
    <el-dialog v-model="previewdialog" :close-on-click-modal="false" class="video-dialog" destroy-on-close="true">
        <video controls>
            <source :src="videoUrl" type="video/mp4" class="video-player">
        </video>
    </el-dialog>
</template>

<script>
import "../css/board.css"
export default {

}
</script>
<script setup>
import { onMounted, getCurrentInstance, ref } from 'vue'
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import { formatDate } from "../config/formatDate.js"
import { getFileTypeByValue, navigateBasedOnFileType } from '@/config/filetype.js';
import { preview } from 'vue3-image-preview';
import { useRouter } from 'vue-router'
import StorageUtil from '../config/storageUtil.js'
import eventBus from '../js/eventBus.js';
import fileConvert from '@/js/fileConvert.js';
import IndexedDBManager from '@/js/IndexedDB.js';
//打开IndexedDB数据库
const dbManager = new IndexedDBManager();
const fileconvert = new fileConvert();
const user = StorageUtil.getUserFromSession()
const router = useRouter();
const { proxy } = getCurrentInstance()
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
const notice_tableData = ref()
const renwu_tableData = ref()
const file_tableData = ref()
const unfinishedTaskNum = ref(0)
const finishedNum = ref(0)
const TotalNum = ref(0)
const videoUrl = ref(null)
const previewdialog = ref(false)
let filelist = null;
onMounted(async () => {
    try {
        // 依次请求
        const [informs, assignments, bimFiles] = await Promise.all([
            fetchWithHandling(proxy.$urls.m().GetInforms, createUrlData(user.emailname)),
            fetchWithHandling(proxy.$urls.m().GetAssigment, createUrlData(user.emailname, projectinfo._id)),
            fetchWithHandling(proxy.$urls.m().GetBIMFile, createUrlData(user.emailname, projectinfo._id))
        ]);
        // 处理第一个请求结果

        notice_tableData.value = informs.data.informInfo.filter(item => item.CivilName === projectinfo.CivilName).sort((a, b) => b.BeinviteTime - a.BeinviteTime); // 降序排序

        // 处理第二个请求结果
        renwu_tableData.value = assignments.data.ProjectTaskinfo.sort((a, b) => b.deadline - a.deadline);
        unfinishedTaskNum.value = assignments.data.ProjectTaskinfo.filter(item => !item.status).length;
        finishedNum.value = assignments.data.ProjectTaskinfo.length - unfinishedTaskNum.value;
        TotalNum.value = assignments.data.ProjectTaskinfo.length;

        // 处理第三个请求结果
        filelist = bimFiles.data.FileShareInfo;
        file_tableData.value = bimFiles.data.FileShareInfo.sort((a, b) => b.UploadTime - a.UploadTime); // 降序排序
        // getPosition(({ latitude, longitude }) => {
        //     getWeather(latitude, longitude); // 确保 getWeather 在获取到位置后执行
        // });
    } catch (error) {
        console.log(error.message)
        new proxy.$tips(error.message, "error").Mess_age();
    }
});
// 获取天气信息的函数
// function getWeather(latitude, longitude) {
//     const apiKey = "284756a3d4a5a4f168e4a96d9ed7c7e4";
//     const url = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}`;
//     fetch(url)
//         .then(response => {
//             // 检查响应是否成功S
//             if (!response.ok) {
//                 throw new Error('Network response was not ok ' + response.statusText);
//             }
//             console.log(response.json())
//             return response.json();
//         })
//         .then(data => {
//             // 处理获取的数据
//             console.log(data); // 打印数据到控制台

//         })
//         .catch(error => {
//             console.error('There has been a problem with your fetch operation:', error);
//         });
// }

const fetchWithHandling = async (url, params) => {
    const res = await new proxy.$request(url, params).modepost();
    if (!res) throw new Error('请求失败');
    if (res.status !== 200) {
        throw new Error(res.data.message);
    }
    if (res.data && res.data.Error === 0) {
        return res;
    }
    const errorMessage = handleError(res);
    new proxy.$tips(errorMessage, "error").Mess_age();
};

const createUrlData = (account, projectId = null) => {
    const urldata = new URLSearchParams();
    urldata.append('Token', getCookie('token'));
    urldata.append('Account', account);
    if (projectId) {
        urldata.append('ProjectId', projectId);
    }
    return urldata;
};
const informtype = (type) => {
    switch (type) {
        case 1: return "任务邀请";
        case 0: return "项目邀请";
    }
}
const getDynamicWidth = (px) => {
    // 根据窗口的宽度动态计算列宽
    const ratio = window.innerWidth / 2560; // 假设 1920 是你设计的基准宽度
    return Math.max(px * ratio, 10) + 'px'; // 保证最小宽度不小于原始值的 80%
}
const file_rowdblclick = async (e) => {
    if (e.FileType == 7 || e.FileType == 8 || e.FileType == 9 || e.FileType == 15) {
        preview({ images: e.OssBucketPath });
    }
    else if (e.FileType == 11) {
        previewdialog.value = true;
        // videoUrl.value = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(e.FilePath)
        videoUrl.value = e.OssBucketPath
    }
    else {
        navigateBasedOnFileType(e)
    }
    if (e.FileType == 1) {
        // 添加数据
        const folder = await getFolderTree()
        await dbManager.setItem('Folderinfo', {
            arr: folder,
            meta: {
                created: Date.now(),
                owner: user.emailname,
            }
        });
        await dbManager.setItem('Fileinfo', {
            arr: filelist.filter(file => file.FileType == 1),
            meta: {
                created: Date.now(),
                owner: user.emailname,
            }
        });
    }
}
const getFolderTree = async () => {
    const urldata = new URLSearchParams();
    urldata.append('Account', user.emailname);
    urldata.append('Token', getCookie('token'));
    urldata.append('ProjectId', projectinfo._id);
    await new proxy.$request(proxy.$urls.m().GetFolder, urldata).modepost().then(res => {
        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            return;
        }
        else {
            if (res.data && res.data.Error == 0) {
                const floderdata = res.data.FoldersInfoProto;
                const folder = fileconvert.convertToTree(floderdata);
                return folder
            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age();
            }
        }
    })
    // 当数据更新了，在dom中渲染后，自动执行该函数

}
const renwu_dbclick = (row) => {
    eventBus.emit("routeTo_renwu", row._id)
}
const notice_dbclick = (row) => {
    router.push({
        name: 'notice',
        query: { id: row._id }
    })
}
// const getPosition = (callback) => {
//     // 检查浏览器是否支持 Geolocation
//     if ("geolocation" in navigator) {
//         navigator.geolocation.getCurrentPosition(
//             function (position) {
//                 // 成功获取定位
//                 const latitude = position.coords.latitude;   // 获取纬度
//                 const longitude = position.coords.longitude; // 获取经度
//                 console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
//                 callback({ latitude, longitude });
//             },
//             function (error) {
//                 // 处理错误
//                 switch (error.code) {
//                     case error.PERMISSION_DENIED:
//                         console.error("User denied the request for Geolocation.");
//                         break;
//                     case error.POSITION_UNAVAILABLE:
//                         console.error("Location information is unavailable.");
//                         break;
//                     case error.TIMEOUT:
//                         console.error("The request to get user location timed out.");
//                         break;
//                     case error.UNKNOWN_ERROR:
//                         console.error("An unknown error occurred.");
//                         break;
//                 }
//             }
//         );
//     } else {
//         console.error("Geolocation is not supported by this browser.");
//     }

// }
</script>
