<template>
    <!-- <div class="xmname">
        <div class="xmname_left"></div>
        
        <div class="xmname_right">
            {{ projectinfo.CivilName }}
        </div>
        
    </div> -->
    <div class="aa">
        <router-link :to="{ name: 'renwulist', query: { flag: 0 } }" class="allxm" :style="{ color: xmColor }"
            @click="updateColor">项目任务{{ count.otherCount }}<div class="bottom_line" v-if="flag == 0"></div>
        </router-link>
        <router-link :to="{ name: 'renwulist', query: { flag: 1 } }" class="create" :style="{ color: createColor }"
            @click="updateCreateColor">发布任务{{ count.createCount }}<div class="bottom_line" v-if="flag == 1"></div>
        </router-link>
        <router-link :to="{ name: 'renwulist', query: { flag: 2 } }" class="accept" :style="{ color: acceptColor }"
            @click="updateAcceptColor">接收任务{{ count.acceptCount }}<div class="bottom_line" v-if="flag == 2"></div>
        </router-link>

        <el-button color="#4976f3" @click="addrenwuDialog" class="create-btn_aa">新建任务</el-button>
    </div>
    <router-view></router-view>
    <!-- <div class="demo-pagination-block">
        <el-pagination v-model:current-page="currentPage3" v-model:page-size="pageSize3" :small="small"
            :disabled="disabled" :background="background" layout="prev, pager, next, jumper" :total="2"
            @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div> -->
    <el-dialog v-model="addrenwu" title="新建任务" venter center round @opened="loadData" :close-on-click-modal="false">
        <el-form :model="rwform" ref="rwcreateRef" :rules="rules">
            <el-form-item label="任务名称" :label-width="formLabelWidth" required prop="name">
                <el-input v-model="rwform.name" placeholder="请输入任务名称" />
            </el-form-item>
            <el-form-item label="任务描述" :label-width="formLabelWidth" required prop="describe">
                <el-input v-model="rwform.describe" placeholder="请输入任务描述" />
            </el-form-item>
            <el-form-item label="项目成员" :label-width="formLabelWidth" required>
                <el-select v-model="rwform.chengyuan" placeholder="请选择项目成员" multiple>
                    <template #header>
                        <el-checkbox v-model="checkAll" :indeterminate="indeterminate" @change="handleCheckAll">
                            All
                        </el-checkbox>
                    </template>
                    <el-option v-for="item in xmcyoptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="上传文件" :label-width="formLabelWidth">
                <el-upload class="rwupload-demo" action="#" multiple :limit="9" :auto-upload="false"
                    v-model:file-list="rwform.fileList" :on-change="beforeUpload">
                    <!-- <el-button type="primary">Click to upload</el-button> -->
                    <el-button class="wenjian"><el-icon>
                            <Plus />
                        </el-icon>选择文件</el-button>
                    <!-- <template #tip>
                        <div class="rwel-upload__tip">
                            jpg/png files with a size less than 500KB.
                        </div>
                    </template> -->
                </el-upload>
            </el-form-item>
            <el-form-item label="截止日期" required prop="jzdate">
                <el-date-picker v-model="rwform.jzdate" type="datetime" placeholder="请选择" style="width: 100%"
                :disabled-date="disabledDate" />
            </el-form-item>

        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="addrenwuclick" :loading="button_loading">新建</el-button>
                <el-button @click="addrenwucancel">
                    取消
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>

import "../css/renwuinfo.css"
import { ref, reactive, watch, getCurrentInstance, onMounted, onBeforeMount, computed } from 'vue';
import { ElMessage } from 'element-plus'
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js';
import eventBus from '../js/eventBus.js';
import filepath from '@/config/filePath.js';
// import blobToBytes from '../js/blobToBytes.js'
import { getFileType, FileType } from '@/config/filetype.js';
import Client from '@/js/Client.js';
// import { useStore } from 'vuex'
export default {
    name: 'RenwuInfo',
    props: {
        msg: String,
    },

}
</script>
<script setup>
import StorageUtil from '../config/storageUtil.js'
import { useStore } from 'vuex'
const store = useStore()
const user = StorageUtil.getUserFromSession()
const client = new Client();
// const store = useStore()
const { proxy } = getCurrentInstance()
const checkAll = ref(false)
const indeterminate = ref(false)
// const activeName1 = ref("first");
// const activeName2 = ref("first");
const addrenwu = ref(false);
// const currentPage3 = ref(1);
// const pageSize3 = ref(5);
// const small = ref(false);
// const disabled = ref(false);
const count = reactive({ createCount: 0, otherCount: 0, acceptCount: 0 })
const rwcreateRef = ref(null);
const button_loading = ref(false);
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))

// const handleSizeChange = () => {
//     console.log("jkliol");
// }
// const handleCurrentChange = () => {
//     console.log("ghjhyu");
// }
const createColor = ref('#666666'); // 创建的颜色
const acceptColor = ref('#666666'); // 接收的颜色
const xmColor = ref('#4976F3');
const flag = ref(0)
const updateCreateColor = () => {
    createColor.value = '#4976F3'; // 点击创建链接时更新颜色样式
    acceptColor.value = '#666666'; // 将接收链接颜色重置
    xmColor.value = '#666666'
    flag.value = 1
};
const updateColor = () => {
    createColor.value = '#666666';
    acceptColor.value = '#666666';
    xmColor.value = '#4976F3'
    flag.value = 0;
}
const addrenwuDialog = () => {
    if (title.value == null) {
        new proxy.$tips("请先创建任务标签再创建任务", "warning").Mess_age()
        return;
    }
    addrenwu.value = true;
}
const updateAcceptColor = () => {
    acceptColor.value = '#4976F3'; // 点击接收链接时更新颜色样式
    createColor.value = '#666666'; // 将创建链接颜色重置
    xmColor.value = '#666666'
    flag.value = 2
};
const disabledDate = (time) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0); // 设置为当天的零点零分零秒
    return time.getTime() < now.getTime();
}

const xmcyoptions = reactive([]);
const rwform = reactive(
    {
        name: "",
        describe: "",
        chengyuan: [],
        fileList: "",
        jzdate: ""
    }
)
const rules = reactive({
    describe: [
        { required: true, message: '请输入任务描述', trigger: 'blur' },
    ],
    jzdate: [
        { required: true, message: '请选择截止日期', trigger: 'blur' },
        {
            validator: (rule, value, callback) => {
                const now = new Date();
                if (value && value.getTime() < now.getTime()) {
                    callback(new Error('截止日期太近了，请重新选择'));
                } else {
                    callback();
                }
            },
            trigger: 'change',
        },
    ],
    name: [
        { required: true, message: '请输入任务名称', trigger: 'blur' },
    ]
})
onMounted(() => {
    eventBus.on('refreshCount', refreshCount);
})
onBeforeMount(() => {
    eventBus.off('refreshCount', refreshCount);
})
const refreshCount = (data) => {
    count.createCount = data.createTotal;
    count.acceptCount = data.acceptTotal;
    count.otherCount = data.otherTotal;
}
const filelist = [];//文件id
const handleFileChange = async (file) => {
    let credentials = null;
    //判断凭证是否过期
    if (client.isCredentialsExpired()) {
        const urldata = new URLSearchParams();
        urldata.append('Account', user.emailname);
        urldata.append('Token', getCookie('token'));
        urldata.append('ProjectId', projectinfo._id);
        const res = await new proxy.$request(proxy.$urls.m().GetSTS, urldata).modepost();
        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            return;
        }
        else {
            if (res.data && res.data.Error == 0) {
                credentials = res.data;
            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age()
            }
        }
    }
    const nameWithoutExtension = file.name.replace(/\.\w+$/, "");
    client.file = file;
    client.credentials = credentials
    // const timestamp = Date.now(); // 获取当前时间戳
    const filename_hashcode = await getHash(file)
    const extara = projectinfo._id + "/" + 0 + "/" + filename_hashcode;
    client.extara = extara;
    await client.createClient();
    let ext = file.name.substring(file.name.lastIndexOf('.'));
    const url = filepath + extara + ext;
    let id = null;
    try {
        const file_data = new URLSearchParams();
        file_data.append('Token', getCookie('token'));
        file_data.append('ProjectId', projectinfo._id);
        file_data.append('Account', user.emailname);
        file_data.append('FileName', nameWithoutExtension);
        file_data.append('fileType', getFileType(file.name.split('.').pop().toLowerCase()));
        file_data.append('FolderId', 0);
        file_data.append('FileSize', file.size);
        file_data.append('OSSPath', url);
        let res = null;
        res = await new proxy.$request(proxy.$urls.m().UploadFile, file_data).modepost();
        if (!res) return false;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age();
            return false
        }
        else {
            if (res.data && res.data.Error == 0 && res.data.FileShareInfo) {
                id = res.data.FileShareInfo._id;
                filelist.push(id);
                return true
            }
            else if (!res.data && res.data.Error != 0) {
                const errorMessage = handleError(res);
                new proxy.$tips("新建任务失败," + errorMessage, "error").Mess_age();
                return false
            }

        }
    }
    catch (e) {
        new proxy.$tips("上传失败", "error").Mess_age();
    }

};
// const handleFileChange = async (file) => {
//     let uploadSuccess = false;
//     const nameWithoutExtension = file.name.replace(/\.\w+$/, "");
//     const chunkSize = 1024 * 1024;
//     const totalChunks = Math.ceil(file.size / chunkSize);
//     let id = null;
//     for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
//         const start = chunkIndex * chunkSize;
//         const end = Math.min(start + chunkSize, file.size);
//         const chunk = file.slice(start, end);
//         // 使用 Blob 转换为base64字符串
//         const byteArray = await blobToBytes(chunk);
//         try {
//             const file_data = new URLSearchParams();
//             file_data.append('Token', getCookie('token'));
//             file_data.append('ProjectId', projectinfo._id);
//             file_data.append('Account', user.emailname);
//             file_data.append('FileName', nameWithoutExtension);
//             file_data.append('fileType', getFileType(file.name.split('.').pop().toLowerCase()));
//             file_data.append('ChunkIndex', chunkIndex);
//             file_data.append('TotalChunks', totalChunks);
//             file_data.append('FileChunk', byteArray);
//             file_data.append('FolderId', 0);
//             file_data.append('FileSize', file.size);
//             let res = null;
//             res = await new proxy.$request(proxy.$urls.m().UploadFile, file_data).modepost();
//             console.log(res)
//             if (!res) return;
//             if (res.status !== 200) {
//                 new proxy.$tips(res.data.message, "warning").Mess_age()
//                 return false; // 上传失败，直接返回
//             }
//             if (res.data?.Error === 0 && res.data.FileShareInfo) {
//                 id = res.data.FileShareInfo._id;
//                 uploadSuccess = true;
//             } else if (res.data?.Error !== 0) {
//                 const errorMessage = handleError(res);
//                 new proxy.$tips("新建任务失败,"+errorMessage, "error").Mess_age();
//                 return false; // 上传失败，直接返回
//             }
//         }
//         catch (e) {
//             new proxy.$tips("上传失败", "error").Mess_age();
//             return false; // 上传失败，直接返回
//         }
//     }
//     if (uploadSuccess && id) {
//         filelist.push(id)
//     }
//     return uploadSuccess;

// };
const title = computed(() => {
    return store.state.title
})
const addrenwuclick = async () => {
    await rwcreateRef.value?.validate(async (valid) => {
        if (!valid) {
            ElMessage.error("请完善表单信息，再提交！");
            return false;
        }
        button_loading.value = true;
        filelist.length = 0;
        for (const file of rwform.fileList) {
            //判断文件是否支持上传

            if (!await handleFileChange(file.raw)) {
                button_loading.value = false; return;
            }

        }
        const urldata = new URLSearchParams();
        urldata.append('Token', getCookie('token'));
        urldata.append('ProjectId', projectinfo._id);
        urldata.append('Account', user.emailname);
        urldata.append('TaskName', rwform.name);
        urldata.append('accountinfos', rwform.chengyuan);
        urldata.append('Description', rwform.describe);
        urldata.append('deadline', Date.parse(rwform.jzdate));
        if (filelist.length > 0) urldata.append('FileInfos', filelist)
        urldata.append('TaskTitleId', title.value.id);
        const res = await new proxy.$request(proxy.$urls.m().AddAssigment, urldata).modepost();

        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age();
            button_loading.value = false;
            return;
        }
        else {
            if (res.data && res.data.Error == 0) {
                eventBus.emit('taskrefresh');
                if (rwform.chengyuan.find((item) => item == user.emailname)) {
                    eventBus.emit("decrement", true);
                }
                new proxy.$tips("添加成功", "success").Mess_age()

            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age()

            }
        }
        addrenwu.value = false;
        button_loading.value = false;
    })

    //发送请求
}
const beforeUpload = (file) => {
    let ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    //计算文件的大小
    const isLt5M = file.size / 1024 / 1024 < 1024;
    //如果大于500M
    if (!isLt5M) {
        new proxy.$tips("文件太大,请重新选择", "warning").Mess_age()
        rwform.fileList.pop();
        return false;
    }

    // 如果文件类型不在允许上传的范围内
    if (!(ext in FileType)) {
        new proxy.$tips("文件类型不支持上传，请重新选择", "warning").Mess_age();
        rwform.fileList.pop();
        return false;
    }

    // 如果文件类型支持
    return true;

}
const addrenwucancel = () => {
    button_loading.value = false;
    addrenwu.value = false;
    rwcreateRef.value.resetFields()
}
watch(() => rwform.chengyuan, (newval) => {
    // console.log(newval)
    // console.log( xmcyoptions.length)
    if (newval.length === 0) {
        checkAll.value = false
        indeterminate.value = false
    } else if (newval.length === xmcyoptions.length) {
        checkAll.value = true
        indeterminate.value = false
    } else {
        indeterminate.value = true
    }
})
const handleCheckAll = (val) => {
    indeterminate.value = false
    if (val) {
        rwform.chengyuan = xmcyoptions.map((_) => _.value)
        console.log(rwform.chengyuan)
    } else {
        rwform.chengyuan = []
        console.log(rwform.chengyuan)
    }

}
const loadData = async () => {
    const urldata = new URLSearchParams();
    urldata.append('Account', user.emailname);
    urldata.append('Token', getCookie('token'));
    urldata.append('ProjectId', projectinfo._id);
    const res = await new proxy.$request(proxy.$urls.m().GetUser, urldata).modepost();
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            xmcyoptions.length = 0;
            res.data.Permissions.forEach(user => {
                xmcyoptions.push({
                    label: user.AcountName,
                    value: user.AcountName
                });
            });

        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }



    }
}
const getHash = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}
</script>