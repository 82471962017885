<template>
  <el-progress :percentage="upload_new_flag" v-if="upload_new_flag" class="upload_new_progress" />
  <div class="File_resize_out">
    <div class="head" :style="{ width: containerWidth_left + '%' }">
      <!-- <div class="head_a">文件夹</div> -->
      <div class="head_b"><el-button type="primary" @click="newfolderclick">+ 新建文件夹</el-button></div>
      <el-tree :data="treeData.arr" :props="defaultProps" @node-click="handleNodeClick" node-key="value"
        :default-expanded-keys="defaultShowNodes.arr" @node-expand="handleNodeExpand"
        @node-collapse="handleNodeCollapse" ref="folderTreeRef" class="file_tac" aria-disabled="false" draggable
        highlight-current="true" :expand-on-click-node="false" @node-drop="handleNodeDrop"
        :default-checked-keys="defaultCheckedNodes.arr" check-strictly="true">
        <template #default="{ node, data }">
          <span class="custom-tree-node">
            <div class="file_icon_outter">
              <img src="../img/folder.svg" class="foldersvg">
              <span>{{ node.label }}</span>
            </div>

            <el-popover placement="bottom" trigger="click"
              :popper-style="{ minWidth: '20px', padding: '1', width: 'auto' }">
              <template #reference>
                <el-image class="file_icon_style" :src="file_detail_icon" />
              </template>
              <template #default>
                <div class="file_detail_icon_inner">
                  <div class="file_detail_a">
                    <el-button type="primary" text :icon="Edit" size="default"
                      @click="editfilenameclick(data)">编辑</el-button>
                  </div>
                  <div class="file_detail_b">
                    <el-button type="info" text :icon="DocumentAdd" size="default"
                      @click="newfolderclickplus(data)">新增</el-button>
                  </div>
                  <div class="file_detail_c">
                    <el-popconfirm title="确定删除这个文件夹?" @confirm="confirmEvent(data)">
                      <template #reference>
                        <el-button type="danger" text :icon="Delete" size="default">删除</el-button>
                      </template>
                    </el-popconfirm>

                  </div>

                </div>
              </template>

            </el-popover>

          </span>
        </template>
      </el-tree>
    </div>
    <div class="File_line" @mousedown="startDrag" @touchstart="startDrag">
    </div>
    <div class="query_head" :style="{ width: containerWidth_right + '%' }">
      <div class="query_title">
        <el-input v-model="keyvalue" placeholder="名称" class="query_file-input" />
        <el-select v-model="typevalue" placeholder="类型" class="query_type-select">
          <el-option v-for="item in file_typeoptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <el-select v-model="uploadervalue" placeholder="上传者" class="query_uploader-select">
          <el-option v-for="item in uploader_file_options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <el-button text class="file-query" type="primary" @click="selectitem">查询</el-button>
        <el-upload class="upload-demo_aa" action :limit="20" :show-file-list='false' :http-request="handleFileChange"
          multiple :on-change="handleFileProgress" :before-upload="beforeUpload" ref="uploadRef">
          <template #trigger>
            <el-button class="query_file-uploader" type="primary" @click="uploadfileclick">上传文件</el-button>
          </template>

        </el-upload>
        <el-button class="query_file-delete" type="danger" @click="deletedispatch" plain
          v-if="selected_file_arr && selected_file_arr.length">删除</el-button>
        <el-button class="query_file-delete" type="primary" @click="downloaddispatch" plain
          v-if="selected_file_arr && selected_file_arr.length">下载</el-button>
      </div>



      <el-table :data="file_tableData.arr" row-key="_id" lazy v-loading="loading" element-loading-text="正在转换中,请稍后"
        border :element-loading-spinner="svg" element-loading-svg-view-box="-10, -10, 50, 50"
        element-loading-background="rgba(122, 122, 122, 0.5)" @row-dblclick="rowdblclick" class="file_table"
        @selection-change="handleSelectionChange" ref="fileTable">
        <el-table-column type="selection" width="55" />
        <el-table-column fixed prop="file_seq" label="序号" :width="getDynamicWidth(121)" align='center' sortable>
          <template #default="{ row }">
            <span v-if="isValidRow(row)">
              {{ row.seq + 1 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="FileName" label="名称" :min-width="getDynamicWidth(500)" align='center' sortable
          show-overflow-tooltip>
          <template #default="{ row }">
            <div class="submited2"
              :style="{ marginLeft: (row.FileParentId == 0 && row.isPromoted) || (row.children && row.children.length) ? '0' : '2vh' }">
              <img :src="getImageSrc(row.FileType)">
              <label>{{ row.FileName }}</label>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="FileType" label="类型" :width="getDynamicWidth(160)" align='center' sortable>
          <template #default="{ row }">
            <span>
              {{ getFileTypeByValue(row.FileType) }}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="FileVersion" label="版本" :width="getDynamicWidth(130)" align='center' sortable />
        <el-table-column prop="FileSize" label="大小" :width="getDynamicWidth(130)" align='center' sortable>
          <template #default="{ row }">
            <span>
              {{ Sizeconvert(row.FileSize) }}
            </span>
          </template></el-table-column>
        <el-table-column prop="UploadAccount" label="上传者" :width="getDynamicWidth(260)" align='center' sortable />
        <el-table-column prop="UploadTime" label="上传日期" :width="getDynamicWidth(220)" align='center' sortable>
          <template #default="{ row }">
            <span>
              {{ formatDate(row.UploadTime) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align='center' :width="getDynamicWidth(320)">
          <template #default="{ row }">
            <div class="outter_file_operator">
              <div class="operator_file">

                <el-upload class="update-demo" action :limit="1" :show-file-list='false'
                  :http-request="handleFileChange" :before-upload="beforeUpdate" :on-change="handleFileUpdateProgress"
                  ref="updateRef">
                  <template #trigger>
                    <el-button link type="primary" size="primary" @click="refreshClick(row)">
                      更新
                    </el-button>
                  </template>

                </el-upload>
                <el-button link type="danger" size="primary" @click="deleteRow(row)">删除</el-button>
                <el-button link type="primary" size="primary" @click="downloadFileDirectly(row)">下载</el-button>

              </div>
              <el-progress v-if="row.progress" :percentage="row.progress" :stroke-width="6"
                :status="row.status_progress" striped striped-flow color="green" :duration="15" class="progress" />
            </div>

          </template>
        </el-table-column>
      </el-table>

    </div>
  </div>

  <!-- <el-button type="primary" style="position: absolute;left: 800px;top: 500px;" @click="docxClick">预览docx</el-button> -->
  <!-- <el-button type="primary" style="position: absolute;left: 800px;top: 700px;" @click="xlsxClick">预览xlsx</el-button> -->
  <!-- <input type="file" @change="handleChange" style="position: absolute;left: 800px;top: 500px;"/> -->
  <!-- <el-dialog v-model="uploaddialog" title="上传文件" width="500" venter>
    <el-form :model="fileform">
      <el-form-item label="上传类型" :label-width="formLabelWidth" required>
        <el-select v-model="fileform.filetype" placeholder="类型">
          <el-option v-for="item in file_typeoptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="上传RVT格式" :label-width="formLabelWidth" required>
        <el-upload v-model:file-list="fileform.fileListRVT" class="rwupload-demo"
          action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" multiple :auto-upload="false">
          <el-button class="wenjian"><el-icon>
              <Plus />
            </el-icon>选择文件</el-button>
        </el-upload>
      </el-form-item>

    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="uploader_submit">上传</el-button>
        <el-button @click="uploaddialog = false">
          取消
        </el-button>
      </div>
    </template>
  </el-dialog> -->
  <el-dialog v-model="refreshdialog" title="上传文件(更新)" width="500" venter>
    <el-form :model="refreshform">
      <el-form-item label="上传类型" :label-width="formLabelWidth" required>
        <el-select v-model="refreshform.refreshtype" placeholder="类型">
          <el-option v-for="item in file_typeoptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="上传文件  " :label-width="formLabelWidth" required>
        <el-upload v-model:file-list="refreshform.filelistplus" class="rwupload-demo"
          action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" multiple :on-preview="handlePreview"
          :on-remove="handleRemove" :before-remove="beforeRemove" :limit="20" :on-exceed="handleExceed">
          <!-- <el-button type="primary">Click to upload</el-button> -->
          <el-button class="wenjian"><el-icon>
              <Plus />
            </el-icon> 选择文件</el-button>
          <template #tip>
            <div class="rwel-upload__tip">
              jpg/png files with a size less than 500KB.
            </div>
          </template>
        </el-upload>
      </el-form-item>


    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="refreshdialog = false">上传</el-button>
        <el-button @click="refreshdialog = false">
          取消
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog v-model="previewdialog" :close-on-click-modal="false" class="video-dialog" destroy-on-close="true">
    <video controls>
      <source :src="videoUrl" type="video/mp4" class="video-player">
    </video>
  </el-dialog>
</template>

<script>
import '../css/file.css'
export default {
  name: 'FileA',
  props: {
    msg: String,
  },

};
</script>
<script setup>
import { reactive, ref, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue';
import { Edit, Delete, DocumentAdd } from '@element-plus/icons-vue';
import { preview } from 'vue3-image-preview';
import eventBus from '../js/eventBus.js';
// import blobToBytes from '../js/blobToBytes.js'
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import fileConvert from '@/js/fileConvert.js';
import { getFileType, getFileTypeByValue, getImageSrc, navigateBasedOnFileType, FileType_show, FileType } from '@/config/filetype.js';
import { formatDate } from "../config/formatDate.js"
import NodeClick from "../js/NodeClick.js"
import Client from '@/js/Client.js';
import filepath from '@/config/filePath.js';
import StorageUtil from '../config/storageUtil.js'
import IndexedDBManager from '@/js/IndexedDB.js';
//打开IndexedDB数据库
const dbManager = new IndexedDBManager();
const user = StorageUtil.getUserFromSession()
const { proxy } = getCurrentInstance()
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
const nodeclick = new NodeClick();
const fileconvert = new fileConvert();
// const client = new Client();
const file_detail_icon = ref(require('@/img/detail.svg'))
const keyvalue = ref("");
const fileTable = ref(null)
const uploadervalue = ref('')
const loading = ref(false)
const typevalue = ref('')
const upload_new_flag = ref(0)
const folderTreeRef = ref(null)
const refreshdialog = ref(false)
const uploadRef = ref(null)
const updateRef = ref(null)
let startPosition = 0;
let isDragging_div = false;
const selected_file_arr = ref()
const previewdialog = ref(false)
const treeData = reactive({
  arr: []
})
const defaultShowNodes = reactive({
  arr: []
})
const containerWidth_left = ref(15)
const containerWidth_right = ref(74)
let filelist = null;
const file_typeoptions = reactive(
  Object.entries(FileType_show).map(([key, value]) => ({
    label: key,
    value: value
  }))
);
const videoUrl = ref(null)
const uploader_file_options = reactive([])
const defaultProps = reactive({
  children: 'children',
  label: 'label',
});
const data = JSON.parse(sessionStorage.getItem('nodeClickinfo'));
const defaultCheckedNodes = reactive({
  arr: []
});
// const load = (row, treeNode, resolve) => {
//   setTimeout(() => {
//     resolve([
//       {
//         fileid: 11,
//         file_name: "临安农村信用社建筑模型",
//         file_type: "RVT",
//         file_version: "V2.0",
//         file_uploader: "丁晓禹",
//         file_uptime: "2024.07.05 11:12",
//       },
//       {
//         fileid: 12,
//         file_name: "临安农村信用社建筑模型",
//         file_type: "RVT",
//         file_version: "V1.0",
//         file_uploader: "丁晓禹",
//         file_uptime: "2024.06.22 17:08",
//       }
//     ]);
//   }, 1000);
// }
const startDrag = (event) => {
  startPosition = event.touches ? event.touches[0].clientX : event.clientX;
  isDragging_div = true;
  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('mouseup', stopDrag);
  window.addEventListener('touchmove', onMouseMove);
  window.addEventListener('touchend', stopDrag);
  document.body.style.userSelect = 'none'; // 禁止全局选择
};
const stopDrag = () => {
  isDragging_div = false;
  startPosition = 0;
  window.removeEventListener('mousemove', onMouseMove);
  window.removeEventListener('mouseup', stopDrag);
  window.removeEventListener('touchmove', onMouseMove);
  window.removeEventListener('touchend', stopDrag);
  document.body.style.userSelect = ''; // 恢复全局选择
}
const onMouseMove = (event) => {
  if (isDragging_div) {
    const delta = (event.touches ? event.touches[0].clientX : event.clientX) - startPosition;
    containerWidth_left.value = containerWidth_left.value + delta / (window.innerWidth) * 20
    containerWidth_right.value = containerWidth_right.value - delta / (window.innerWidth) * 20
    startPosition = event.touches ? event.touches[0].clientX : event.clientX; // 更新起始位置
  }
}
const file_tableData = reactive({
  arr: []
})
// const fileform = reactive(
//   {
//     filetype: "",
//     fileListRVT: ""
//   }
// )
let nodeclickId = null;
let ids = null;
const Sizeconvert = (bytes) => {
  if (bytes < 1024) { // 小于 1KB，显示为字节
    return bytes + " B";
  } else if (bytes < 1024 * 1024) { // 小于 1MB，显示为 KB
    return (bytes / 1024).toFixed(0) + " KB";
  } else { // 大于等于 1MB，显示为 MB
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  }
}
let temp_fileData = [];
const handleNodeClick = (data) => {
  nodeclickId = data.value;
  data.projectId = projectinfo._id;//新增项目的id,便于识别
  sessionStorage.setItem("nodeClickinfo", JSON.stringify(data))
  ids = nodeclick.traverseAndCollectChildren(data);
  let fileData = filelist.filter(file => ids.includes(file.FolderId));
  temp_fileData = fileData.slice();
  file_tableData.arr = fileconvert.buildTree(fileData);

};
const refreshform = reactive(
  {
    refreshtype: "",
    filelistplus: ""
  }
)

let edit_flag = null;//标识正在点击的行或者说将要操作的行
let temp_edit = null;//标识正在更新的行数据
const refreshClick = (data) => {
  edit_flag = data;
}
const rowdblclick = async (e) => {
  if (e.FileType == 7 || e.FileType == 8 || e.FileType == 9 || e.FileType == 15) {
    preview({ images: e.OssBucketPath });
  }
  else if (e.FileType == 11) {
    previewdialog.value = true;
    // videoUrl.value = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(e.FilePath)
    videoUrl.value = e.OssBucketPath
  }
  else {
    navigateBasedOnFileType(e)
  }
  if (e.FileType == 1) {
    // 添加数据
    await dbManager.setItem('Folderinfo', {
      arr: treeData.arr,
      meta: {
        created: Date.now(),
        owner: user.emailname,
      }
    });
    await dbManager.setItem('Fileinfo', {
      arr: filelist.filter(file => file.FileType == 1),
      meta: {
        created: Date.now(),
        owner: user.emailname,
      }
    });
  }


}

const handleSelectionChange = (items) => {
  selected_file_arr.value = items;
}
const deletedispatch = () => {
  ElMessageBox.confirm('是否删除所有选中文件?此操作无法撤销', '提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    //删除所有选中行
    let flag = false;
    for (let i = 0; i < selected_file_arr.value.length; i++) {
      const data = selected_file_arr.value[i];
      flag = await deleteFileFunc(data);//删除当前所在行的文件
      if (!flag) break;
      //删除所在行的所有子文件
      for (let j = 0; j < data.children.length; j++) {
        flag = await deleteFileFunc(data.children[j])
        if (!flag) break;
      }

    }
    if (flag) {
      new proxy.$tips("删除成功", "success").Mess_age();
    }
    await refreshFileList(temp_fileProgress);
  }).catch(() => {
    new proxy.$tips("已取消", "info").Mess_age();
  })
}
const downloaddispatch = () => {
  console.log(selected_file_arr.value)
  try {
    selected_file_arr.value.forEach((item) => {
      downloadFileDirectly(item)
    });
    fileTable.value.clearSelection();
  }
  catch {
    new proxy.$tips("下载失败", "error").Mess_age();
  }

}
const beforeUpload = (file) => {
  let ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
  //计算文件的大小
  const isLt5M = file.size / 1024 / 1024 < 1024;
  //如果大于500M
  if (!isLt5M) {
    new proxy.$tips("文件太大,请重新选择", "warning").Mess_age()
    return false;
  }

  // 如果文件类型不在允许上传的范围内
  if (!(ext in FileType)&&ext!="3dm") {
    new proxy.$tips("文件类型不支持上传，请重新选择", "warning").Mess_age();
    return false;
  }

  // 如果文件类型支持
  return true;

}
// const handleFileProgress = (file) => {
//   console.log(file, uploadRef.value)
// }
const beforeUpdate = (file) => {
  //限定选择文件类型
  const uploadType = {
    0: '.glb',
    1: '.rvt',
    2: '.pdf',
    3: '.pptx',
    4: '.docx',
    5: '.xlsx',
    6: '.dwg'
  }
  //截取文件的后缀，判断文件类型
  const FileExt = file.name.replace(/.+\./, "").toLowerCase();
  //计算文件的大小
  const isLt5M = file.size / 1024 / 1024 < 1024;
  //如果大于500M
  if (!isLt5M) {
    new proxy.$tips("文件太大,请重新选择", "warning").Mess_age()
    return false;
  }
  if (edit_flag.FileType !== getFileType(FileExt)) {
    console.log(edit_flag)
    new proxy.$tips("文件类型不正确，请选择" + getFileTypeByValue(edit_flag.FileType) + "格式", "warning").Mess_age()
    return false;
  }
}
const handleFileUpdateProgress = (file) => {
  updateRef.value.clearFiles()
}
const uploadfileclick = (e) => {
  edit_flag = null;
  // acceptTypes.value = ""
}
let temp_fileProgress = []
const uploadQueue = new Map();   // 存储所有上传任务 { fileName: { progress, status } }
let totalFiles = 0;          // 总文件数
let credentials = null;
let completedFiles = 0;        // 已完成文件数（成功+失败）
const handleFileChange = async (event) => {
  if (nodeclickId == null) {
    uploadRef.value.clearFiles();
    ElMessage.warning("请选择文件夹！")
    return
  }
  const folderId = nodeclickId;
  const file = event.file;
  totalFiles = totalFiles + 1; // 总文件数+1
  uploadQueue.set(file.name, {
    progress: 0,
    status: 'pending', // pending/success/error
  });
  temp_edit = edit_flag;
  const client = new Client(file.name,
    (fileName, percent) => updateFileProgress(fileName, percent));
  //判断凭证是否过期
  if (client.isCredentialsExpired()) {
    const urldata = new URLSearchParams();
    urldata.append('Account', user.emailname);
    urldata.append('Token', getCookie('token'));
    urldata.append('ProjectId', projectinfo._id);
    const res = await new proxy.$request(proxy.$urls.m().GetSTS, urldata).modepost();
    if (!res) return;
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        credentials = res.data;
      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age()
      }
    }
  }

  const nameWithoutExtension = file.name.replace(/\.\w+$/, "");
  client.file = file;
  client.credentials = credentials
  // const timestamp = Date.now(); // 获取当前时间戳
  const filename_hashcode = await getHash(file)
  const extara = projectinfo._id + "/" + folderId + "/" + filename_hashcode;
  client.extara = extara;
  await client.createClient();
  let ext = file.name.substring(file.name.lastIndexOf('.'));
  const url = filepath + extara + ext;
  try {
    const file_data = new URLSearchParams();
    file_data.append('Token', getCookie('token'));
    file_data.append('ProjectId', projectinfo._id);
    file_data.append('Account', user.emailname);
    file_data.append('FileName', nameWithoutExtension);
    file_data.append('fileType', getFileType(file.name.split('.').pop().toLowerCase()));
    file_data.append('FolderId', folderId);
    file_data.append('FileSize', file.size);
    file_data.append('OSSPath', url);
    let res = null;
    if (temp_edit) {
      file_data.delete('FolderId')
      file_data.delete('fileType')
      temp_edit.progress = 0;
      if (temp_edit.FileParentId) {
        file_data.append('FileParentId', temp_edit.FileParentId);
      }
      else {
        file_data.append('FileParentId', temp_edit._id);
      }

      res = await new proxy.$request(proxy.$urls.m().UpdateFile, file_data).modepost();
    }
    else {
      upload_new_flag.value = 0;
      res = await new proxy.$request(proxy.$urls.m().UploadFile, file_data).modepost();
    }
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
    }
    else {
      if (res.data && res.data.Error == 0) {
        temp_fileProgress = file_tableData.arr;
        await refreshFileList(temp_fileProgress);
        uploadQueue.set(file.name, {
          ...uploadQueue.get(file.name),
          status: 'success'
        });

      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
        uploadQueue.set(file.name, {
          ...uploadQueue.get(file.name),
          status: 'error'
        });
      }
    }
  }
  catch (e) {
    new proxy.$tips("上传失败", "error").Mess_age();
    if (temp_edit) {
      temp_edit.status_progress = 'warning'
    }
  }
  finally {
    completedFiles = completedFiles + 1; // 已完成文件数+1;
    checkAllFilesDone(); // 检查是否全部完成
  }
  uploadRef.value.clearFiles();
  updateRef.value.clearFiles()
};
const refreshProgress = (progressvalue) => {
  // 更新进度条
  if (temp_edit) {
    temp_edit.progress = Math.round(progressvalue);
  }
  else {
    upload_new_flag.value = Math.round(progressvalue);
  }
}
// 更新单个文件进度
const updateFileProgress = (fileName, percent) => {
  const task = uploadQueue.get(fileName);
  if (task) {
    task.progress = percent;
    calculateTotalProgress(); // 重新计算总进度
  }
}
// 计算总进度（按文件平均）
const calculateTotalProgress = () => {
  const tasks = Array.from(uploadQueue.values());
  const total = tasks.reduce((sum, task) => sum + task.progress, 0);
  const average = total / tasks.length;
  refreshProgress(average); // 发送总进度
  // eventBus.emit('refreshProgress', average); // 发送总进度
}
// 检查是否所有文件完成
const checkAllFilesDone = () => {
  if (completedFiles === totalFiles) {
    const allSuccess = Array.from(uploadQueue.values())
      .every(task => task.status === 'success');

    if (allSuccess) {
      new proxy.$tips("文件上传成功", "success").Mess_age();
    }
    //  else {
    //   new proxy.$tips("部分文件上传失败", "error").Mess_age();
    // }
    // 重置状态
    uploadQueue.clear();
    totalFiles = 0;
    completedFiles = 0;
  }
}
const getHash = async (file) => {
  const arrayBuffer = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}
const isValidRow = (row) => {
  return row.children && row.children.length > 0 || (row.FileParentId == 0 && row.isPromoted);
}

const getDynamicWidth = (px) => {
  // 根据窗口的宽度动态计算列宽
  const ratio = window.innerWidth / 2560; // 假设 1920 是你设计的基准宽度
  return Math.max(px * ratio, 10) + 'px'; // 保证最小宽度不小于原始值的 80%
}
const downloadFileDirectly = (item) => {
  // const link = document.createElement('a');
  // // link.href = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(item.FilePath);  // 使用已知的直接文件URL
  // link.href = item.OssBucketPath
  // link.download = item.FileName + "." + getFileTypeByValue(item.FileType).toLowerCase();
  // document.body.appendChild(link);
  // link.click();             // 触发下载
  // document.body.removeChild(link);
  fetch(item.OssBucketPath)
    .then(res => res.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = item.FileName + "." + getFileTypeByValue(item.FileType).toLowerCase(); // 强制生效的文件名 
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    });
};

// const downloadClick = async (item) => {
//   console.log(item)
//   loading.value = true;
//   const chunkSize = 1024 * 1024;
//   const totalChunks = Math.ceil(item.FileSize / chunkSize);
//   let fileBytes = []; // 用于存储解码的二进制数据
//   let flag = true;
//   for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
//     const file_data = new URLSearchParams();
//     file_data.append('Token', getCookie('token'));
//     file_data.append('ProjectId', projectinfo._id);
//     file_data.append('Account', user.emailname);
//     file_data.append('FileId', item._id);
//     // file_data.append('Fileversion', item.FileVersion);
//     file_data.append('StartPosition', chunkIndex * chunkSize);
//     await new proxy.$request(proxy.$urls.m().DownloadFile, file_data).modepost().then(res => {
//       if (res.status != 200) {
//         new proxy.$tips(res.data.message, "warning").Mess_age()
//         flag = false;
//         return;
//       }
//       else {
//         if (res.data.Error == 0 && res && res.data && res.data.FileContent && res.data.FileContent.$binary && res.data.FileContent.$binary.base64) {
//           try {
//             let base64Chunk = res.data.FileContent.$binary.base64;
//             const byteCharacters = atob(base64Chunk);  // Base64 转原始字符串
//             const byteNumbers = new Array(byteCharacters.length);
//             for (let i = 0; i < byteCharacters.length; i++) {
//               byteNumbers[i] = byteCharacters.charCodeAt(i);
//             }
//             const byteArray = new Uint8Array(byteNumbers);

//             // 存储解码后的 Uint8Array 数据块
//             fileBytes.push(byteArray);
//           } catch (decodeError) {
//             new proxy.$tips("Base64 解码失败", "error").Mess_age();
//             flag = false;
//             return;
//           }
//         }
//         else {
//           const errorMessage = handleError(res);
//           new proxy.$tips(errorMessage, "error").Mess_age();
//           flag = false;
//           return;
//         }
//       }
//     })

//   }
//   if (flag == false) return;
//   try {
//     const totalLength = fileBytes.reduce((acc, chunk) => acc + chunk.length, 0); // 计算总长度
//     const combinedArray = new Uint8Array(totalLength); // 创建合并后的数组
//     let offset = 0;
//     fileBytes.forEach(chunk => {
//       combinedArray.set(chunk, offset); // 从 offset 开始设置数据
//       offset += chunk.length;
//     });
//     // 将拼接后的块数组转换为 Blob
//     const blob = new Blob([combinedArray], { type: 'application/octet-stream' });
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     console.log(link.href)
//     link.download = item.FileName + "." + getFileTypeByValue(item.FileType).toLowerCase();
//     document.body.appendChild(link);
//     link.click(); // 触发下载
//     loading.value = false;
//     document.body.removeChild(link);
//   }
//   catch (error) {
//     new proxy.$tips("下载失败", "error").Mess_age()
//     console.log(error)
//   }


// }
onBeforeUnmount(() => {
  eventBus.off('refreshProgress', refreshProgress)
})
onMounted(async () => {
  eventBus.on('refreshProgress', refreshProgress)
  await refreshFileList(temp_fileProgress)
  await refreshFolderTree();

  //文件
  if (data) {
    //防止其他项目的缓存影响本项目
    if (data.projectId == projectinfo._id) {
      ids = nodeclick.traverseAndCollectChildren(data);
      let fileData = filelist.filter(file => ids.includes(file.FolderId));
      temp_fileData = fileData.slice();
      file_tableData.arr = fileconvert.buildTree(fileData);
      defaultCheckedNodes.arr = [data.value]
      nodeclickId = data.value
      //高亮的节点
      folderTreeRef.value.setCurrentKey(data.value);
    }

  }
  //展开的节点
  const temp_Nodes = JSON.parse(sessionStorage.getItem('defaultShowNodes'));
  if (temp_Nodes) {
    defaultShowNodes.arr = temp_Nodes;
  }
  const urldata = new URLSearchParams();
  urldata.append('Account', user.emailname);
  urldata.append('Token', getCookie('token'));
  urldata.append('ProjectId', projectinfo._id);
  const res = await new proxy.$request(proxy.$urls.m().GetUser, urldata).modepost();
  if (!res) return;
  if (res.status != 200) {
    new proxy.$tips(res.data.message, "warning").Mess_age()
    return;
  }
  else {
    if (res.data && res.data.Error == 0) {
      uploader_file_options.length = 0;
      console.log(res)
      res.data.Permissions.forEach(user => {
        uploader_file_options.push({
          label: user.AcountName,
          value: user.AcountName
        });
      });
    }
    else {
      const errorMessage = handleError(res);
      new proxy.$tips(errorMessage, "error").Mess_age()
    }
  }
})
const newfolderclick = () => {
  ElMessageBox.prompt('请输入文件夹名称', '新建', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    inputPattern: /.+/,
    inputErrorMessage: '文件夹名称不能为空',
  })
    .then(async ({ value }) => {
      await createFolder(value, 0);
    })

}
// const xlsxClick = () => {
//   router.push({
//     name: "excelviewer",
//   });
// }
const editfilenameclick = (data) => {
  ElMessageBox.prompt('请输入文件夹名称', '重命名', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    inputPattern: /^(?!.*[<>]).+$/,
    inputErrorMessage: '文件夹名称不能为空',
    inputValue: data.label,
  })
    .then(async ({ value }) => {
      const urldata = new URLSearchParams();
      urldata.append('Account', user.emailname);
      urldata.append('Token', getCookie('token'));
      urldata.append('ProjectId', projectinfo._id);
      urldata.append('FolderId', data.value);
      urldata.append('FolderName', value);
      urldata.append('ParentID', -1);
      await new proxy.$request(proxy.$urls.m().ModifyFolder, urldata).modepost().then(res => {
        console.log(res)
        if (!res) return;
        if (res.status != 200) {
          new proxy.$tips(res.data.message, "warning").Mess_age()
          return;
        }
        else {
          if (res.data && res.data.Error == 0) {
            new proxy.$tips("修改成功", "success").Mess_age()
            refreshFolderTree();
          }
          else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age();
          }
        }
      })
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '取消修改',
      })
    })
}
const newfolderclickplus = (data) => {
  ElMessageBox.prompt('请输入文件夹名称', '新建', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    inputPattern: /^(?!.*[<>]).+$/,
    inputErrorMessage: '文件夹名称不能为空',
  })
    .then(async ({ value }) => {
      await createFolder(value, data.value);
    })

}
const confirmEvent = async (data) => {
  const urldata = new URLSearchParams();
  urldata.append('Account', user.emailname);
  urldata.append('Token', getCookie('token'));
  urldata.append('ProjectId', projectinfo._id);
  urldata.append('FolderId', data.value);
  await new proxy.$request(proxy.$urls.m().DeleteFolder, urldata).modepost().then(res => {
    if (!res) return;
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        if(data.value==nodeclickId){
          sessionStorage.removeItem("nodeClickinfo");
          nodeclickId = null;//清楚缓存数据
        }
        refreshFolderTree();
      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
}
const refreshFolderTree = async () => {
  const urldata = new URLSearchParams();
  urldata.append('Account', user.emailname);
  urldata.append('Token', getCookie('token'));
  urldata.append('ProjectId', projectinfo._id);
  await new proxy.$request(proxy.$urls.m().GetFolder, urldata).modepost().then(res => {
    if (!res) return;
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        const floderdata = res.data.FoldersInfoProto;
        if (floderdata.length == 0) {
          sessionStorage.removeItem("nodeClickinfo");
          nodeclickId = null;//清楚缓存数据
          file_tableData.arr = [];
        }
        treeData.arr = fileconvert.convertToTree(floderdata);

      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
  // 当数据更新了，在dom中渲染后，自动执行该函数

}
const refreshFileList = async (temp_fileProgress) => {
  const urldata = new URLSearchParams();
  urldata.append('Account', user.emailname);
  urldata.append('Token', getCookie('token'));
  urldata.append('ProjectId', projectinfo._id);
  await new proxy.$request(proxy.$urls.m().GetBIMFile, urldata).modepost().then(res => {
    if (!res) return;
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        filelist = res.data.FileShareInfo;
        filelist = fileconvert.initializeFileData(filelist, temp_fileProgress);//添加进度条相关信息

        if (ids && ids.length) {
          // file_tableData.arr = filelist.filter(file => ids.includes(file.FolderId));
          let fileData = filelist.filter(file => ids.includes(file.FolderId));
          file_tableData.arr = fileconvert.buildTree(fileData);

        }
        // else{
        //   file_tableData.arr = filelist;
        // }
      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
}
const createFolder = async (value, flag) => {
  const urldata = new URLSearchParams();
  urldata.append('Account', user.emailname);
  urldata.append('Token', getCookie('token'));
  urldata.append('ProjectId', projectinfo._id);
  urldata.append('ParentID', flag);
  urldata.append('FolderName', value);
  await new proxy.$request(proxy.$urls.m().CreateFolder, urldata).modepost().then(res => {
    console.log(res)
    if (!res) return;
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        refreshFolderTree();
      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
}
// 树节点展开
const handleNodeExpand = (data) => {
  // 保存当前展开的节点
  let flag = false
  defaultShowNodes.arr.some(item => {
    if (item === data.value) { // 判断当前节点是否存在， 存在不做处理
      flag = true
      return true
    }
  })
  if (!flag) { // 不存在则存到数组里
    defaultShowNodes.arr.push(data.value)
  }
  sessionStorage.setItem('defaultShowNodes', JSON.stringify(defaultShowNodes.arr))
}
// 树节点关闭
const handleNodeCollapse = (data) => {
  console.log(data)
  defaultShowNodes.arr.some((item, i) => {
    if (item === data.value) {
      // 删除关闭节点
      defaultShowNodes.arr.splice(i, 1)
    }
  })
  removeChildrenIds(data)
}
//删除树子节点
const removeChildrenIds = (data) => {
  //懒加载的时候想要删除子节点  得在加载下级数据时添加上data.children
  if (data.children) {
    data.children.forEach(function (item) {
      const index = defaultShowNodes.arr.indexOf(item.value)
      if (index != -1) {
        defaultShowNodes.arr.splice(index, 1)
      }
      removeChildrenIds(item)
    })
  }
  sessionStorage.setItem('defaultShowNodes', JSON.stringify(defaultShowNodes.arr))
}
const deleteRow = (data) => {
  ElMessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    if (data.children && data.children.length > 0) {
      ElMessageBox.confirm('是否删除所有版本文件?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).then(async () => {
        let flag = deleteFileFunc(data);//删除当前所在行的文件
        if (!flag) return;
        //删除所在行的所有子文件
        for (let i = 0; i < data.children.length; i++) {
          flag = deleteFileFunc(data.children[i])
          if (!flag) break;
        }
        if (flag) {
          new proxy.$tips("删除成功", "success").Mess_age();
          refreshFileList(temp_fileProgress);
        }
      })
        .catch(() => {
          if (deleteFileFunc(data)) {
            new proxy.$tips("删除成功", "success").Mess_age();
            refreshFileList(temp_fileProgress);
          }
        })
    }
    else {
      //只删除当前文件
      if (deleteFileFunc(data)) {
        new proxy.$tips("删除成功", "success").Mess_age();
        refreshFileList(temp_fileProgress);
      }
    }


  }).catch(() => {
    // 取消删除
    ElMessage({
      type: 'info',
      message: '已取消删除',
    })
  })
}
// const nameChange = (data) => {
//   file_tableData.arr = filelist.filter(item => {
//     return item.FileName.toLowerCase().includes(data.toLowerCase())
//   })

// }
const selectitem = () => {
  let list = temp_fileData.filter(item => {
    const hasKeyValue = keyvalue.value && keyvalue.value.trim() !== '';
    const hasTypeValue = typevalue.value && typevalue.value !== '';
    const hasUploaderValue = uploadervalue.value && uploadervalue.value.trim() !== '';
    return (
      (!hasKeyValue || item.FileName.toLowerCase().includes(keyvalue.value.toLowerCase())) &&
      (!hasTypeValue || item.FileType === typevalue.value) &&
      (!hasUploaderValue || item.UploadAccount === uploadervalue.value)
    );
  })
  file_tableData.arr = fileconvert.buildTree(list);
}
const deleteFileFunc = async (data) => {
  // 确认删除
  const urldata = new URLSearchParams();
  urldata.append('Account', user.emailname);
  urldata.append('Token', getCookie('token'));
  urldata.append('ProjectId', projectinfo._id);
  urldata.append('FileId', data._id);
  urldata.append('Fileversion', data.FileVersion);
  try {
    const res = await new proxy.$request(proxy.$urls.m().DeleteFile, urldata).modepost();
    if (!res || res.status !== 200) {
      return false
    }
    if (res.data && res.data.Error !== 0) {
      const errorMessage = handleError(res);
      new proxy.$tips(errorMessage, "error").Message();
      return false
    }
    return true; // 删除成功
  } catch (error) {
    new proxy.$tips(error.message, "error").Message();
    return false; // 删除失败
  }
}
// const typeChange = (data) => {
//   file_tableData.arr = filelist.filter(file =>
//     file.FileType == data
//   );
// }
// const uploaderChange = (data) => {
//   file_tableData.arr = filelist.filter(file =>
//     file.UploadAccount == data
//   );
// }
const handleNodeDrop = async (nodefrom, nodeto, position) => {
  console.log(nodefrom, nodeto, position)
  let pos = null;
  if (position == 'inner') {
    pos = nodeto.data.value;
  }
  else {
    pos = nodeto.data.parentID;
  }
  const urldata = new URLSearchParams();
  urldata.append('Account', user.emailname);
  urldata.append('Token', getCookie('token'));
  urldata.append('ProjectId', projectinfo._id);
  urldata.append('FolderId', nodefrom.data.value);
  urldata.append('ParentID', pos);
  // urldata.append('ProjectId', data.FileVersion);
  await new proxy.$request(proxy.$urls.m().ModifyFolder, urldata).modepost().then(res => {
    console.log(res)
    if (!res) return;
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        new proxy.$tips("修改成功", "success").Mess_age()
        refreshFolderTree();
      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
}
</script>
