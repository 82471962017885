<template>
  <div class="mine">
    <div class="mine_aa">
      个人中心
    </div>
    <div class="mine_return">
      <el-button type="primary" plain @click="CloseView"> <el-icon size="25">
          <ArrowLeftBold />
        </el-icon>返回</el-button>
    </div>
    <div class="mine_content">
      <div class="mine_body">

        <el-menu :default-active="active" class="el-menu-vertical-mine" :router="true">
          <el-menu-item index="/PersonalInformation" class="menu-item-centered">个人信息</el-menu-item>
          <el-menu-item index="/UnitInformation" class="menu-item-centered"> 单位信息</el-menu-item>
        </el-menu>
        <div class="routeContent">
          <!-- 确保数据加载完成再传递 -->
          <router-view v-if="userdata" :userData="userdata"></router-view>
        </div>
      </div>

    </div>

  </div>


</template>

<script>
import "../css/mine.css"
export default {
  name: 'MineA',
  props: {
    msg: String
  },


}
</script>
<script setup>
import { onMounted, getCurrentInstance, ref, onBeforeUnmount } from 'vue'
import handleError from '../config/HandleError.js'
import { getCookie } from '../store/cookieUtil.js';
import StorageUtil from '../config/storageUtil.js'
import eventBus from '@/js/eventBus.js';
import{ useRouter} from 'vue-router'
const user = StorageUtil.getUserFromSession()
const { proxy } = getCurrentInstance()
const active = ref("/PersonalInformation")
const fromPath = ref(null) // 用于记录来源路由
// const previousPath=ref(null)
// onMounted(()=>{
//   pre
// })
const router = useRouter();
const userdata = ref(null)
onMounted(async () => {
  eventBus.on("refreshMes", refreshMes)
  fromPath.value = router.options.history.state.back || '/' // 默认返回首页
  await refreshMes()
})
onBeforeUnmount(() => {
  eventBus.off("refreshMes", refreshMes)
})
const refreshMes = async () => {
  const urldata = new URLSearchParams();
  urldata.append('Account', user.emailname);
  urldata.append('Token', getCookie('token'));
  await new proxy.$request(proxy.$urls.m().GetAccountMessage, urldata).modepost().then(res => {

    if (!res) return;
    if (res.status != 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age()
      return;
    }
    else {
      if (res.data && res.data.Error == 0) {
        userdata.value = res.data
      }
      else {
        const errorMessage = handleError(res);
        new proxy.$tips(errorMessage, "error").Mess_age();
      }
    }
  })
}
const CloseView=()=>{
  if (fromPath.value) {
    router.push(fromPath.value) // 跳转到记录的来源路由
  } else {
    router.push('/') // 默认返回首页
  }
}
</script>
<style></style>