<template>
  <section>
    <div class="head_title">
      <div class="head_left">
      <div class="head_center">
        <img src="../img/72-2.png" alt="" class="logo">
      </div>
        <div class="head_center">
          <router-link to="/projectList" class="project">项目中心</router-link>
        </div>
        
        <!-- <router-link to="/datacenter" class="static">数据中心</router-link> -->
      </div>

      <!-- <div class="project"><router-link to="/projectitem">项目中心</router-link></div>
      <div class="project"><router-link to="/projectitem">数据中心</router-link></div> -->
      <!-- <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="User" name="first">项目中心</el-tab-pane>
    <el-tab-pane label="Config" name="second">数据中心</el-tab-pane>
      </el-tabs> -->
      <div class="head_right">
        <el-badge :value=noticeCount class="item" :max="99">
          <img src="../img/notice1.svg" @click="noticeshow" class="noticesvg">
        </el-badge>
        <img :src="convertsrc" class="noticesvg" @click="convert_English" />
        <div class="avaterdiv">
          <el-avatar class="head_avatar" @mouseenter="handleMouseEnterAvatar" @mouseleave="handleMouseLeaveAvatar" @click="handleAvatarClick" ref="avatar"
            :src="avatarUrl"></el-avatar>
        </div>

        <el-card v-if="showCard" class="demo-card" shadow="hover" @mouseenter="handleMouseEnterCard" ref="card"
          @mouseleave="handleMouseLeaveCard">
          <template #header>
            <div class="card-header">
              <span> {{ nickname }}</span>
            </div>
          </template>
          <div class="mine_card" @click="mineclick">
            个人中心
          </div>
          <template #footer>
            <div class="logout_card" @click="logout">
              退出登录
            </div>
          </template>
        </el-card>
      </div>

      <!-- <el-dropdown>
        <span class="nickname">
          {{ nickname }}
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="mineclick">个人中心</el-dropdown-item>
            <el-dropdown-item @click="logout">退出登录</el-dropdown-item>

          </el-dropdown-menu>
        </template>
      </el-dropdown> -->
      <!-- <div class="nickname">struggle66</div> -->
    </div>

    <router-view></router-view>
  </section>
</template>

<script>
export default {
  name: 'HeaderComp',
  props: {
    msg: String
  }
}
</script>
<script setup>
import '../css/head.css'
import { ref, onMounted, getCurrentInstance, onUnmounted,watch,onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { eraseCookie, getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import eventBus from '../js/eventBus.js';
import StorageUtil from '../config/storageUtil.js'
import fileConvert from '@/js/fileConvert.js';
const fileconvert = new fileConvert();
const { proxy } = getCurrentInstance()
const router = useRouter()
const nickname = ref('请登录')
const avatarUrl = ref(require('@/img/avatarurl.png'));
const noticeCount = ref(0)
const showCard = ref(false)
const convertsrc = ref(require('../img/中英文切换-显示中文.svg'))
const avatar = ref(null)
const card = ref(null)
// 检测当前设备是否支持触摸操作
const isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0)// 点击头像处理
const handleAvatarClick = () => {
  if (isTouchDevice) {
    showCard.value = true
  }
}

// 文档点击处理
const handleDocumentClick = (event) => {
  if (!showCard.value) return
  
  const isClickInsideAvatar = avatar.value?.$el.contains(event.target)
  const isClickInsideCard = card.value?.$el.contains(event.target)

  if (!isClickInsideAvatar && !isClickInsideCard) {
    showCard.value = false
  }
}

// 监听 showCard 变化来添加/移除点击事件
watch(showCard, (newVal) => {
  if (isTouchDevice) {
    if (newVal) {
      document.addEventListener('click', handleDocumentClick)
    } else {
      document.removeEventListener('click', handleDocumentClick)
    }
  }
})

// 组件卸载时移除监听
onBeforeUnmount(() => {
  if (isTouchDevice) {
    document.removeEventListener('click', handleDocumentClick)
  }
})

// 修改原有的鼠标事件处理（保持桌面端逻辑）
const handleMouseEnterAvatar = () => {
  if (!isTouchDevice) {
    showCard.value = true
  }
}

const handleMouseLeaveAvatar = () => {
  if (!isTouchDevice && !mouseOnCard) {
    showCard.value = false
  }
}
let mouseOnCard = false
const handleMouseEnterCard = () => {
  mouseOnCard = true
}
const handleMouseLeaveCard = () => {
  mouseOnCard = false
  // 如果鼠标离开卡片，且没有在头像上，隐藏卡片
  showCard.value = false;
}
const noticeshow = () => {
  console.log("njfdnv");
  router.push({
    name: "notice"
  })
}
// const returnproject = () => {
//   router.push({
//     name: "projectList"
//   })
// }
const user = StorageUtil.getUserFromSession()
onMounted(async () => {
  start(); // 组件挂载时开始轮询
  nickname.value = user.emailname;
  eventBus.on('decrement', updateCount);
  eventBus.on('updateAvatar', updateAvatar);
  eventBus.on('updateCount_act', updateCount_act);
  // await Promise.all([fetchInforms(), fetchAccountMessage()]);
  await fetchAccountMessage();
})
onUnmounted(() => {
  stop(); // 组件卸载时停止轮询
  eventBus.off('decrement', updateCount); // 清理事件
  eventBus.off('updateAvatar', updateAvatar);
  eventBus.off('updateCount_act', updateCount_act);
});

const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  eraseCookie('token');
  if ('caches' in window) {
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });
  }
  router.push({
    name: "login"
  })
}
let Chinese = true
const convert_English = () => {
  if (Chinese) {
    convertsrc.value = require('../img/中英文切换-显示英文.svg')
  } else {
    convertsrc.value = require('../img/中英文切换-显示中文.svg')
  }
  Chinese = !Chinese;

}
const updateCount = (flag) => {
  if (flag) {
    noticeCount.value += 1;
  }
  else {
    if (noticeCount.value > 0) {
      noticeCount.value -= 1; // 仅在大于 0 时减少 1
    }
  }

};
const updateCount_act = (data) => {
  noticeCount.value = data;
}
const updateAvatar = (data) => {
  avatarUrl.value = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(data);
}
const mineclick = () => {
  router.push({
    name: "mine"
  })
}
// 封装请求处理函数
const handleRequest = async (url, params, successCallback) => {
  try {
    const res = await new proxy.$request(url, params).modepost();
    if (!res) return;

    if (res.status !== 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age();
      return;
    }

    if (res.data && res.data.Error === 0) {
      successCallback(res.data);
    } else {
      const errorMessage = handleError(res);
      new proxy.$tips(errorMessage, "error").Mess_age();
    }
  } catch (error) {
    console.error("请求失败:", error);
    // new proxy.$tips("请求失败，请稍后重试", "error").Mess_age();
  }
};
let pollInterval = 5000; // 初始间隔为 5 秒
const startPolling = () => {
  let pollingTimer = null;
  const stopPolling = () => {
    if (pollingTimer) {
      clearInterval(pollingTimer);
      pollingTimer = null;
    }
  };
  const start = () => {
    const poll = async () => {
      await fetchInforms();
      pollingTimer = setTimeout(poll, pollInterval); // 使用 setTimeout 实现动态间隔
    };

    poll(); // 开始轮询
  };
  return { start, stop: stopPolling };
};
const { start, stop } = startPolling();
// 获取通知信息
const fetchInforms = async () => {
  const urldata = new URLSearchParams();
  urldata.append('Token', getCookie('token'));
  urldata.append('Account', user.emailname);

  await handleRequest(proxy.$urls.m().GetInforms, urldata, (data) => {
    noticeCount.value = data.informInfo.filter(item => !item.IsRead).length;
    // 根据未读消息数量动态调整轮询间隔
    if (noticeCount.value > 1) {
      pollInterval = 300000; // 如果有未读消息，加快轮询,1分钟
    } else {
      pollInterval = 300000; // 如果没有未读消息，恢复默认间隔，5分钟
    }
  });
};
// 获取用户头像信息
const fetchAccountMessage = async () => {
  const urldata = new URLSearchParams();
  urldata.append('Token', getCookie('token'));
  urldata.append('Account', user.emailname);
  await handleRequest(proxy.$urls.m().GetAccountMessage, urldata, (data) => {
    if (data.IconPath !== null) {
      avatarUrl.value = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(data.IconPath);
    }

  });
};

</script>