import CryptoJS from 'crypto-js';
const secretKey = process.env.VUE_APP_SECRET_KEY 
// 加密函数
function encryptData(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
}

// 解密函数
function decryptData(ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

// 存储工具类
class StorageUtil {
    // 保存用户信息到 localStorage（加密）
    static saveUserToLocal(user) {
        const encryptedData = encryptData(user);
        localStorage.setItem('user', encryptedData);
    }

    // 从 localStorage 获取用户信息（解密）
    static getUserFromLocal() {
        const encryptedData = localStorage.getItem('user');
        if (encryptedData) {
            return decryptData(encryptedData);
        }
        return null;
    }

    // 保存用户信息到 sessionStorage（加密）
    static saveUserToSession(user) {
        const encryptedData = encryptData(user);
        sessionStorage.setItem('user', encryptedData);
    }

    // 从 sessionStorage 获取用户信息（解密）
    static getUserFromSession() {
        const encryptedData = sessionStorage.getItem('user');
        if (encryptedData) {
            return decryptData(encryptedData);
        }
        return null;
    }

    // 清除 localStorage 中的用户信息
    static clearUserFromLocal() {
        localStorage.removeItem('user');
    }

    // 清除 sessionStorage 中的用户信息
    static clearUserFromSession() {
        sessionStorage.removeItem('user');
    }
}

export default StorageUtil;