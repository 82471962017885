const ERROR_CODES = {
    200001: "请求重复",
    200002: "登录信息为空",
    200003: "账号格式错误",
    200004: "密码格式错误",
    200005: "账号在黑名单中,请联系管理员",
    200006: "登录密码错误",
    200007: "其他账号登录中",
    200008: "游戏网关登录错误",
    200009: "会话玩家错误",
    200010: "没有玩家",
    200011: "重新进入游戏错误",
    200012: "玩家会话错误",
    200013: "重新进入游戏错误",
    200014: "进入游戏错误",
    200015: "登录过期,请重新登录",
    200016: "角色名称为空",
    200017: "角色名称相同",
    200018: "角色不存在",
    200019: "该账户不存在",
    200020: "电话为空",
    200021: "账号已存在",
    200022: "电话和邮箱都为空",
    200023: "项目已存在",
    200024: "项目不存在",
    200025: "邀请人已存在",
    200026: "邀请人没有权限,请联系管理员",
    200027: "权限不足,请联系管理员",
    200028: "项目数量不足,请联系客服咨询",
    200029: "经理为空",
    200030: "成员已满",
    200031: "项目分配已存在",
    200032: "未加入此项目",
    200033: "项目分配不存在",
    200034: "项目分配无权限,请联系管理员",
    200035: "文件未找到",
    200036: "角色权限配置已存在",
    200037: "角色未找到",
    200038: "无权限,请联系管理员",
    200039: "无AR Smart Cloud权限，请联系管理员",
    200040: "无权限,请联系管理员",
    200041: "无删除权限,请联系管理员",
    200042: "角色编号已存在",
    200043: "无成员邀请权限，请联系管理员",
    200044: "角色已存在",
    200045: "用户未找到",
    200046: "无上传文件权限,请联系管理员",
    200047: "角色配置不存在",
    200048: "文件名无效",
    200049: "文件夹名称无效",
    200050: "文件夹命名不合法",
    200051: "文件夹名称已找到",
    200052: "文件夹名称相同",
    200053: "任务已完成,暂不可修改",
    200054: "文件格式错误",
    200055: "没有信息消息",
    200056: "项目已创建",
    200057: "没有存储空间,请联系客服",
    200058: "未完成权限",
    200059: "发送 OSS 路径",
    200060: "资源更新版本错误",
    200061: "资源更新清单错误",
    200062: "资源更新下载错误",
    200063: "未找到权限",
    200064: "角色权限配置不存在",
    200065: "套餐不存在",
    200066: "销售管理不存在",
    200067: "发票管理已存在",
    200068: "发票管理不存在",
    200069: "验证码错误",
    200070: "角色权限配置未更改",
    200071: "无角色权限变更权限",
    200072: "无提交权限",
    200073: "电子邮件无效",
    200074: "无其他VIP状态",
    200075: "此标签下面仍有任务，无法删除",
    200076: "任务标签已删除",
    200077: "未找到任务标签"

};
const handleError = (res) => {
    if (res.data && res.data.Error) {
        const errorCode = res.data.Error;
        return ERROR_CODES[errorCode] || "未知错误"; // 若未定义的错误码则返回"未知错误"
    }
    return "没有返回错误信息"; // 如果没有返回错误信息
}
export default handleError;
