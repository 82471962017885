<template>
    <el-form :model="form" label-width="auto" style="max-width: 600px">
        <el-form-item label="单位名称">
            <el-input v-model="form.WorkUnit" />
        </el-form-item>
        <el-form-item label="职务">
            <el-input v-model="form.Post
                " />
        </el-form-item>
        <el-form-item label="统一社会信用代码">
            <el-input v-model="form.USCC
                " />

        </el-form-item>
        <el-form-item label="开户行">
            <el-input v-model="form.AccountWithBank
                " />
        </el-form-item>
        <el-form-item label="账号">
            <el-input v-model="form.BankAccount
                " />
        </el-form-item>
        <el-form-item label="开票电话">
            <el-input v-model="form.BillingAddress
                " />
        </el-form-item>
        <el-form-item label="地址">
            <el-input v-model="form.Address" />
        </el-form-item>

    </el-form>
    <div class="saveMes">
        <el-button type="primary" class="mine_submit" @click="saveMessage">保存</el-button>
    </div>

</template>

<script>
export default {

}
</script>
<script setup>
import { reactive, onMounted, getCurrentInstance, defineProps } from 'vue'
import handleError from '../config/HandleError.js'
import { getCookie } from '../store/cookieUtil.js';
import StorageUtil from '../config/storageUtil.js'
import blobToBytes from '../js/blobToBytes.js'
import eventBus from '@/js/eventBus.js';
import fileConvert from '@/js/fileConvert.js';
const fileconvert = new fileConvert();
const user = StorageUtil.getUserFromSession()
const { proxy } = getCurrentInstance()
const porps = defineProps({
    userData: {
        type: Object,
        required: true,
    }
})
const form = reactive({
    WorkUnit: '',
    Post: '',
    USCC: '',
    BankAccount: '',
    AccountWithBank: '',
    BillingAddress: '',
    Address: '',
})
onMounted(() => {
    const userData = porps.userData;
    form.BillingAddress = userData.BillingAddress || "";
    form.AccountWithBank = userData.AccountWithBank || "";
    form.BankAccount = userData.BankAccount || "";
    form.USCC = userData.USCC || "";
    form.Post = userData.Post || "";
    form.WorkUnit = userData.WorkUnit || "";
    form.Address = userData.Address || "";


})
const saveMessage = async () => {
    const urldata = new URLSearchParams();
    // 检查每个字段是否非空，非空时才添加到 FormData 中
    if (form.WorkUnit && form.WorkUnit.trim() !== '') {
        urldata.append('WorkUnit', form.WorkUnit);
    }

    if (form.Post && form.Post.trim() !== '') {
        urldata.append('Post', form.Post);
    }

    if (form.USCC && form.USCC.trim() !== '') {
        urldata.append('USCC', form.USCC);
    }

    if (form.AccountWithBank && form.AccountWithBank.trim() !== '') {
        urldata.append('AccountWithBank', form.AccountWithBank);
    }

    if (form.BankAccount && form.BankAccount.trim() !== '') {
        urldata.append('BankAccount', form.BankAccount);
    }

    if (form.BillingAddress && form.BillingAddress.trim() !== '') {
        urldata.append('BillingAddress', form.BillingAddress);
    }

    if (form.Address && form.Address.trim() !== '') {
        urldata.append('Address', form.Address);
    }

    urldata.append('account', user.emailname);
    urldata.append('Token', getCookie('token'));

    urldata.append('Name', porps.userData.Name);
    urldata.append('Sex', porps.userData.Sex);

    const iconUrl = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(porps.userData.IconPath)
    const response = await fetch(iconUrl);
    const blob = await response.blob(); // 将响应转换为 Blob 对象
    const byteArray = await blobToBytes(blob);
    urldata.append('coverData', byteArray);
    const startIndex = iconUrl.lastIndexOf('.') + 1; // 加1是为了跳过点号
    const extensionWithoutDot = iconUrl.substring(startIndex);
    urldata.append('ImageType', extensionWithoutDot);
    await new proxy.$request(proxy.$urls.m().ModifyAccountMessage, urldata).modepost().then(res => {
        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            return;
        }
        else {
            if (res.data && res.data.Error == 0) {
                eventBus.emit("refreshMes");
                new proxy.$tips("修改成功", "success").Mess_age();
            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age();
            }
        }
    })
}
</script>
<style></style>