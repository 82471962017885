<template>
    <div class="bb">
        <el-tabs class="demo-tabs2" @tab-click="handleClick2" v-model="activeName2">
            <el-tab-pane :label="`全部任务 ${allCount}`" name="-1">
                <div class="scroll-container">
                    <renwuitemRelease></renwuitemRelease>
                    <!-- <renwuitemRelease v-if="flag == 1"></renwuitemRelease>
                    <renwuitemAccept v-else></renwuitemAccept> -->
                </div>
            </el-tab-pane>
            <el-tab-pane :label="`进行中 ${unfinishedCount}`" name="0">
                <div class="scroll-container">
                    <renwuitemRelease></renwuitemRelease>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="`已完成 ${finishedCount}`" name="1">
                <div class="scroll-container">
                    <renwuitemRelease></renwuitemRelease>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div class="sequence">
            <el-select v-model="paixuvalue" placeholder="排序" @change="paixuChange">
                <el-option v-for="item in paixuoptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-icon v-if="defaultseq" @click="sequenceClick">
                <Top />
            </el-icon>
            <el-icon v-else @click="ReverseClick">
                <Bottom />
            </el-icon>
        </div>

        <el-button type="default" size="default" class="collapse" @click="collapseclick">{{ collapse_text }}</el-button>
        <!-- <div class="collapse" @click="collapseclick">{{ collapse_text }}</div> -->
    </div>
</template>
<script>


export default {
    name: "RenwuList",

    components: {
        renwuitemRelease,
    }
}
</script>
<script setup>
import renwuitemRelease from "./renwuitemRelease.vue"
import "../css/renwulist.css"
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import { ref, onMounted, watch, getCurrentInstance, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex'
import eventBus from '../js/eventBus.js';
import StorageUtil from '../config/storageUtil.js'
const user = StorageUtil.getUserFromSession()
const store = useStore()
const route = useRoute()
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
const { proxy } = getCurrentInstance()
const flag = ref(0);
const allCount = ref(0);
const unfinishedCount = ref(0);
const finishedCount = ref(0);
let allrenwulist = null;
const collapse_text = ref('全部展开');
const paixuoptions = [
    { value: '1', label: '发布时间' },
    { value: '2', label: '截止时间' },
    { value: '3', label: '任务编号' },
    { value: '4', label: '任务状态' },
]
const defaultseq = ref(true)
const paixuvalue = ref(null);
// const rwlist = reactive([]);
// let rwlists=null;
const activeName2 = ref('-1');
const handleClick2 = (e) => {
    store.dispatch('statusfun', e.props.name)
}
onMounted(async () => {
    if (route.query.flag != undefined) {
        flag.value = route.query.flag;  // 从 query 中获取 flag
    }

    await refreshData();
    eventBus.on('taskrefresh', refreshData);


});
let newtitle = null;
watch(() => route.query.flag, async (newFlag) => {
    flag.value = newFlag;
    await refreshData();
});
watch(() => store.state.title, async (newTitle) => {
    newtitle = newTitle;
    await refreshData();
});
const refreshData = async () => {
    const urldata = new URLSearchParams();
    urldata.append('Token', getCookie('token'));
    urldata.append('Account', user.emailname);
    urldata.append('ProjectId', projectinfo._id);
    const res = await new proxy.$request(proxy.$urls.m().GetAssigment, urldata).modepost();
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            allrenwulist = res.data.ProjectTaskinfo;
            if (newtitle) {
                allrenwulist = allrenwulist.filter(item => item.TaskId == newtitle.id)
            }
            else{
                return
            }
            let assignmentlistshow = null;
            let createTotal = allrenwulist.filter(item => item.creatorName == user.emailname);
            let acceptTotal = allrenwulist.filter(item => item.accountinfos.includes(user.emailname));
            let otherTotal = allrenwulist;
            //通知父组件
            eventBus.emit('refreshCount', { createTotal: createTotal.length, acceptTotal: acceptTotal.length, otherTotal: otherTotal.length });
            //我创建的
            if (flag.value == 1) {
                assignmentlistshow = createTotal;

            }
            //我接收的
            else if (flag.value == 2) {
                assignmentlistshow = acceptTotal
            }
            //项目全部任务
            else {
                assignmentlistshow = otherTotal
            }
            sessionStorage.setItem("flag", flag.value);
            allCount.value = assignmentlistshow.length;
            unfinishedCount.value = assignmentlistshow.filter(item => item.status == false).length;
            finishedCount.value = assignmentlistshow.filter(item => item.status == true).length;
            // assignmentlistshow.forEach(element => {
            //     AddUserItem(element)
            // });
            store.dispatch('rwlistfun', assignmentlistshow)
            console.log(allrenwulist, assignmentlistshow)
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()
        }
    }
}
onBeforeUnmount(() => {
    // 在组件销毁时取消事件监听
    eventBus.off('taskrefresh', refreshData);
});

const sequenceClick = () => {
    defaultseq.value = false;
    //时间从近期开始排
    eventBus.emit('sort_reverse');
}
//处理数据
// const AddUserItem = (task) => {
//     task.accountinfos.forEach(account => {
//         const userIndex = task.Submissioninfo.findIndex((i) => i.UserAccount === account);
//         if (userIndex == -1) {
//             //若没有该用户,就新建一条空白回复
//             task.Submissioninfo.push({
//                 UserAccount: account,
//                 Comments: "——", // 默认评论为空
//                 SubmissionTime: null, // 默认提交时间为空
//                 fileInfos: [] // 默认文件数组为空
//             });
//         }
//     });
// }
const ReverseClick = () => {
    defaultseq.value = true;
    eventBus.emit('sort_reverse');
}
const collapseclick = () => {
    //展开

    if (collapse_text.value == '全部展开') {
        collapse_text.value = '全部折叠'
        eventBus.emit('collapse', true);
    }
    else {
        collapse_text.value = '全部展开'
        eventBus.emit('collapse', false);
    }
}
const paixuChange = (value) => {
    eventBus.emit('sort', value);
}
</script>
