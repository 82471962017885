<template>
	<div class="preview_title">
		<el-button type="primary" @click="CloseView"> <el-icon size="25">
				<ArrowLeftBold />
			</el-icon>返回</el-button>
		<div class="preview_text">
			<span>{{ msg }}</span>
		</div>

	</div>
	<!-- <iframe :src="pptUrl" class="preview_content"></iframe> -->
	<vue-office-pptx :src="pptUrl" id="preview_content" @rendered="renderedHandler"
      @error="errorHandler"/>
</template>
<script>
export default {
	name: 'PdfViewer',
	props: {
		msg: String
	},
	components: {
		VueOfficePptx
	},
}
</script>
<script setup>
import { ref, onMounted } from 'vue';
import VueOfficePptx from '@vue-office/pptx';
// import fileConvert from '@/js/fileConvert.js';
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { ElLoading } from 'element-plus'
const router = useRouter();
// const fileconvert = new fileConvert();
const pptUrl = ref('')
const msg = ref('预览')
let loadingInstance=null;
onMounted(() => {
	try {
		loadingInstance = ElLoading.service({
			target: "#preview_content",
			lock: true,
		})
		loadingInstance.setText (`加载中,请稍候`);
		const fileinfo = JSON.parse(sessionStorage.getItem('fileinfo'))
		msg.value = fileinfo.FileName
		// const url = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(fileinfo.FilePath)
		const url = fileinfo.OssBucketPath
		pptUrl.value = url;
	}
	catch {
		ElMessageBox.alert('打开失败', '提示', {
			confirmButtonText: '退出',
			type: 'error'
		}).then(() => {
			router.back();
		}).catch(err => {
			console.error("登录失效提示框错误:", err);
		});
	}

})
const renderedHandler=()=>{
	loadingInstance.close()
}
const errorHandler=()=>{
	loadingInstance.setText (`加载失败`);
}
const CloseView = () => {
	router.back();//浏览器历史回退
}

</script>
<style scoped>
.m-ppt-wrap {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.m-iframe {
	width: 100%;
	flex: 1;
	border: none;
}

.preview_title {
	height: 30px;
	font-size: 15px;
	display: flex;
	background-color: #ffffff;

}

.preview_text {
	width: calc(100vw - 30px);
	/* 使用100vw来代表视口宽度 */
	text-align: center;
	/* 保持文本右对齐 */
	display: flex;
	justify-content: center;
	align-items: center;
}

#preview_content {
	width: 100vw;
	height: 97vh;
}
</style>
