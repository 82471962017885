<template>
  <section>
    <div class="title">
      <img src="../img/72-2.png" alt="">
      <a class="linklogin" @click="loginplus">立即登录</a>
    </div>
    <form action="" id="forget-confirm">
      <div class="two_inner">
        <div class="two_passwordtext">忘记密码</div>
        <span id="confirm-email">注册码已发送至：{{ confirm_email }}</span><br />
        <div class="two_text">
          验证码有效期为5分钟
        </div>
        <el-input v-model="verifycode" type="text" placeholder=" 请输入验证码" class="register_email" />
        <el-input v-model="password" type="password" show-password placeholder="设置密码(长度必须是6~30位字符)"
          class="register_verify_one" />
        <el-input v-model="passwordplus" type="password" show-password placeholder="确认密码" class="register_verify_one" />
        <el-button type="primary" @click="registerplus" class="register_submit_one">下一步</el-button>
      </div>
    </form>
  </section>
</template>
<script>
import '../css/forgettwo.css'
import { reactive, ref, toRefs,getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import handleError from '../config/HandleError.js'
import { setCookie } from '../store/cookieUtil.js';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const router = useRouter();
    const store = useStore();
    const userinfo = reactive({
      verifycode: "",
      password: "",
      passwordplus: ""
    })
    const registerplus = async() => {
      if (userinfo.verifycode == "" || userinfo.password == "" || userinfo.passwordplus == "") {
        alert("请完善表单");
        return;
      }
      else if (userinfo.password.length < 6 || userinfo.password.length > 30 || userinfo.passwordplus.length < 6 || userinfo.passwordplus.length > 30) {
        alert("密码的位数必须在6~30之间")
        return;
      }
      else if (userinfo.password == userinfo.passwordplus) {
        const urldata = new URLSearchParams();
        urldata.append('Phone', confirm_email.value);
        urldata.append('NewPassword', userinfo.password);
        urldata.append('Code', userinfo.verifycode);
        const res = await new proxy.$request(proxy.$urls.m().ModifyPassword, urldata).modepost();
        console.log(res)
        if (!res) return;
        if (res.status != 200) {
          new proxy.$tips(res.data.message, "warning").Mess_age()
          return;
        }
        else {
          if (res.data && res.data.Error == 0) {
            //登录成功
            new proxy.$tips("注册成功", "success").Mess_age()
            router.push({
              name: 'forgetthree'
            })
            setCookie('token', res.data.Token, 7);
          }
          else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

          }
        }

      }
      else {
        alert("两次密码输入不一致")
        return;
      }
    }
    const confirm_email = ref(store.state.emailname);
    const loginplus = () => {
      router.push({
        name: 'login'
      })
    }
    return {
      confirm_email,
      registerplus,
      ...toRefs(userinfo),
      loginplus
    }
  }
}
</script>
<style scoped></style>