<template>
    <div class="preview_title">
        <el-button type="primary" @click="CloseView"> <el-icon size="25">
                <ArrowLeftBold />
            </el-icon>返回</el-button>
        <div class="preview_text">
            <span>{{ msg }}</span>
        </div>

    </div>
    <div id="docx-preview-wrap">
        <!-- <input type="file" @change="upload" /> -->
        <vue-office-pdf :src="source" :options="options" style="height: 97vh;width: 100%" @rendered="renderedHandler"
            @error="errorHandler" />
    </div>
</template>
<script>
export default {
    name: 'PdfViewer',
    props: {
        msg: String
    },
}
</script>
<script setup>
import { ref } from 'vue'
import VueOfficePdf from '@vue-office/pdf'
import { onMounted } from 'vue';
// import fileConvert from '@/js/fileConvert.js';
import { useRouter } from 'vue-router'
import { ElLoading } from 'element-plus'
const router = useRouter();
// const fileconvert = new fileConvert();
const source = ref()
const msg = ref('预览')
let loadingInstance=null;
onMounted(() => {
    loadingInstance = ElLoading.service({
        target: "#docx-preview-wrap",
        lock: true,
    })
    loadingInstance.setText(`加载中,请稍候`);
    const fileinfo = JSON.parse(sessionStorage.getItem('fileinfo'))
    msg.value = fileinfo.FileName
    // source.value = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(fileinfo.FilePath)
    source.value = fileinfo.OssBucketPath
    console.log(source.value)
})
const options = {
    // xls:  false ,       //预览xlsx文件设为false；预览xls文件设为true
    minColLength: 0,  // excel最少渲染多少列，如果想实现xlsx文件内容有几列，就渲染几列，可以将此值设置为0.
    minRowLength: 0,  // excel最少渲染多少行，如果想实现根据xlsx实际函数渲染，可以将此值设置为0.
    widthOffset: 10,  //如果渲染出来的结果感觉单元格宽度不够，可以在默认渲染的列表宽度上再加 Npx宽
    heightOffset: 10, //在默认渲染的列表高度上再加 Npx高
    beforeTransformData: (workbookData) => { return workbookData }, //底层通过exceljs获取excel文件内容，通过该钩子函数，可以对获取的excel文件内容进行修改，比如某个单元格的数据显示不正确，可以在此自行修改每个单元格的value值。
    transformData: (workbookData) => { return workbookData }, //将获取到的excel数据进行处理之后且渲染到页面之前，可通过transformData对即将渲染的数据及样式进行修改，此时每个单元格的text值就是即将渲染到页面上的内容
}
const CloseView = () => {
    router.back();//浏览器历史回退
}
const renderedHandler=()=>{
	loadingInstance.close()
}
const errorHandler=()=>{
	loadingInstance.setText (`加载失败`);
}
</script>
<style scoped>
#docx-preview-wrap {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* 确保子元素垂直布局 */
}

#bodyContainer {
    height: 100%;
    flex-grow: 1;
    /* 允许容器在可用空间中增长 */
    overflow-y: auto;
    /* 允许垂直滚动 */
    padding: 5px;
    /* 可选：添加一些内边距，防止内容和边缘粘连 */

}

.preview_title {
    height: 30px;
    font-size: 15px;
    display: flex;
    background-color: #ffffff;
    padding-left: 10px;

}

.preview_text {
    width: calc(100vw - 30px);
    /* 使用100vw来代表视口宽度 */
    text-align: center;
    /* 保持文本右对齐 */
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
