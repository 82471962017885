<template>
    <div class="avatar-container_mine">
        <div class="mine_inner">
            <div class="avater_personal">
                <img :src="avatarUrl" alt="头像" class="avatar_mine" />
            </div>
            <el-upload :show-file-list='false' :before-upload="beforeUpload" accept="image/*" @change="onFileSelected">
                <template #trigger>
                    <div class="camera-icon_mine">
                        <img :src="cameraIconUrl" alt="摄影图标" @mouseenter="isLt5M = true" @mouseleave="isLt5M = false" />
                    </div>
                </template>
                <template #tip>
                    <div class="el-upload__tip" v-if="isLt5M">
                        图片大小应小于500kb
                    </div>
                </template>
            </el-upload>

        </div>

    </div>
    <el-form :model="form" label-width="auto" style="max-width: 600px">
        <el-form-item label="姓名">
            <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="性别">
            <el-radio-group v-model="form.gender">
                <el-radio value="1">男</el-radio>
                <el-radio value="2">女</el-radio>
            </el-radio-group>

        </el-form-item>
        <el-form-item label="联系电话">
            <el-input v-model="form.phone" disabled="true"/>
        </el-form-item>
        <el-form-item label="联系邮箱">
            <el-input v-model="form.email" disabled="true"/>
        </el-form-item>

        <el-form-item label="权限状态">
            <span>{{ form.quanxian }}</span>
        </el-form-item>
        <!-- <div v-for="(item, index) in form.VIPConfigProto.slice(1)" :key="index">{{ item.VIPName
        }}:有效期({{ formatDate(item.VIPStateStartTime) }}-{{ formatDate(item.VIPStateEndTime) }})</div> -->
    </el-form>
    <div class="saveMes">
        <el-button type="primary" class="mine_submit" @click="saveMessage">保存</el-button>
    </div>

    <!-- <input type="file" ref="fileinput" @change="onFileSelected" style="display: none" accept="image/*" /> -->
</template>

<script>
import "../css/PersonalInformation.css"
import eventBus from '@/js/eventBus.js';
export default {

}
</script>
<script setup>
import { reactive, onMounted, getCurrentInstance, ref, defineProps } from 'vue'
import handleError from '../config/HandleError.js'
import { getCookie } from '../store/cookieUtil.js';
import { formatDate } from "../config/formatDate.js"
import StorageUtil from '../config/storageUtil.js'
import blobToBytes from '../js/blobToBytes.js'
import { FileType } from '@/config/filetype.js';
import fileConvert from '@/js/fileConvert.js';
const user = StorageUtil.getUserFromSession()
const fileconvert = new fileConvert();
const { proxy } = getCurrentInstance()
const avatarUrl = ref(require('@/img/avatarurl.png'))
const cameraIconUrl = ref(require('@/img/camera.svg'))
const form = reactive({
    name: '',
    gender: '',
    phone: '',
    email: '',
    title: '',
    company: '',
    quanxian: '',
    VIPConfigProto: []
})
const porps = defineProps({
    userData: {
        type: Object,
        required: true,
    }
})
const isLt5M = ref(false)
onMounted(() => {
    const userData = porps.userData;
    form.name = userData.Name || "";
    form.gender = String(userData.Sex);
    form.phone = userData.Phone || "";
    form.email = userData.Mail || "";
    form.title = userData.Post || "";
    form.company = userData.WorkUnit || "";
    // form.quanxian = res.data.VIPName + "(有效期：" + formatDate(res.data.VIPStateStartTime) + "-" + formatDate(res.data.VIPStateEndTime) + ")"
    if (userData.IconPath !== null) {
        avatarUrl.value = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(userData.IconPath)
    }
    form.VIPConfigProto = userData.VIPConfigProto
    const permission = userData.VIPConfigProto.at(-1)
    if (userData.VIPConfigProto.length < 1) {
        form.quanxian = userData.VIPName + "(有效期：" + formatDate(permission.VIPStateStartTime) + "-" + formatDate(permission.VIPStateEndTime) + ")"
    }
    else {
        form.quanxian = permission.VIPName + "(有效期：" + formatDate(permission.VIPStateStartTime) + "-" + formatDate(permission.VIPStateEndTime) + ")"
    }

})
const beforeUpload = (file) => {
    let ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    //计算文件的大小
    const isLt5M = file.size / 1024 < 512;
    //如果大于500M
    if (!isLt5M) {
        new proxy.$tips("文件大于512kb,请重新选择", "warning").Mess_age()
        return false;
    }

    // 如果文件类型不在允许上传的范围内
    if (!(ext in FileType)) {
        new proxy.$tips("文件类型不支持上传，请重新选择", "warning").Mess_age();
        return false;
    }
    imageFile = file; // 获取选择的第一个文件
    avatarUrl.value = URL.createObjectURL(imageFile);
    // 如果文件类型支持
    return true;

}

let imageFile = null;
const saveMessage = async () => {
    const userData = porps.userData;
    const urldata = new URLSearchParams();
    urldata.append('account', user.emailname);
    urldata.append('Token', getCookie('token'));
    urldata.append('Name', form.name);
    urldata.append('Sex', form.gender);
    urldata.append('Post', userData.Post);
    urldata.append('WorkUnit', userData.WorkUnit);
    urldata.append('USCC', userData.USCC);
    urldata.append('AccountWithBank', userData.AccountWithBank);
    urldata.append('BankAccount', userData.BankAccount);
    urldata.append('BillingAdress', userData.BillingAddress);
    urldata.append('Address',userData.Address);
    if (imageFile != null) {
        const byteArray = await blobToBytes(imageFile);
        urldata.append('coverData', byteArray);
        urldata.append('ImageType', imageFile.name.split('.').pop());
    }
    else {
        const response = await fetch(avatarUrl.value);
        console.log(avatarUrl.value)
        const blob = await response.blob(); // 将响应转换为 Blob 对象
        const byteArray = await blobToBytes(blob);
        urldata.append('coverData', byteArray);
        const startIndex = avatarUrl.value.lastIndexOf('.') + 1; // 加1是为了跳过点号
        const extensionWithoutDot = avatarUrl.value.substring(startIndex);
        urldata.append('ImageType', extensionWithoutDot);
    }
    await new proxy.$request(proxy.$urls.m().ModifyAccountMessage, urldata).modepost().then(res => {
        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            return;
        }
        else {
            if (res.data && res.data.Error == 0) {
                new proxy.$tips("修改成功", "success").Mess_age();
                if (res.data.IconPath !== null) {
                    eventBus.emit("updateAvatar", res.data.IconPath);
                    avatarUrl.value = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(res.data.IconPath)
                }
                eventBus.emit("refreshMes");

            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age();
            }
        }
    })
}
</script>
<style></style>