import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import WebGL from 'three/addons/capabilities/WebGL.js';
const Initialize = () => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 6000);
    const renderer = new THREE.WebGLRenderer({
        alpha: true, // canvas是否包含alpha (透明度) 默认为 false
        antialias: true,//抗锯齿
        precision: 'lowp',//声明变量精度高低lowp、mediump和highp。
        // 设置对数深度缓冲区，优化深度冲突问题
        logarithmicDepthBuffer: true
    });//创建渲染器，快门，拍照
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    renderer.autoClear = true;
    renderer.localClippingEnabled = true;
    renderer.setPixelRatio(window.devicePixelRatio);//避免canvas画布输出模糊
    renderer.shadowMap.enabled = true;   // 启用阴影
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // 选择阴影类型
    //解决加载gltf格式模型颜色偏差问题
    // renderer.outputColorSpace = THREE.SRGBColorSpace;
    const x = 0, y = 0, z = 0;
    camera.lookAt(x, y, z);//拍摄方向
    // camera.position.set(3, 3, 0);
    // const cameraHelper = new THREE.CameraHelper(camera);
    //添加轨道控制器
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = false;//禁止默认缩放
    controls.enableRotate = true;
    controls.enablePan = false;//禁止默认平移
    controls.rotateSpeed = 0.1
    controls.target.set(x, y, z);
    controls.update();//update()会执行  camera.lookAt(100, 0,0);
    //改变视角时进行监听,鼠标参数改变要进行重新渲染
    controls.addEventListener('change', function () {
        // console.log(camera.position, controls.target)
    })
    // controls.enabled=false;
    //添加平行光
    const directionalLight = new THREE.DirectionalLight(0xfffff0, 4);
    directionalLight.castShadow = true;
    // 设置三维场景计算阴影的范围
    directionalLight.shadow.camera.left = -50;
    directionalLight.shadow.camera.right = 50;
    directionalLight.shadow.camera.top = 200;
    directionalLight.shadow.camera.bottom = -100;
    directionalLight.shadow.camera.near = 0.5;
    directionalLight.shadow.camera.far = 600;
    directionalLight.shadow.bias = -1;
    scene.add(directionalLight);
    // scene.add(cameraHelper);
    //以下的方法可以帮助你检测当前用户所使用的环境是否支持WebGL，如果不支持，将会向用户提示一条信息。
    if (WebGL.isWebGLAvailable()) {
        // Initiate function or other initializations here
        animate();

    } else {
        const warning = WebGL.getWebGLErrorMessage();
        document.getElementById('my-three').appendChild(warning);

    }
    const texture = createTextureBackground();
    const selectedColor = JSON.parse(localStorage.getItem("bgc"))
    if (selectedColor && selectedColor.bgcType == 'color') {
        scene.background = new THREE.Color(selectedColor.bgccolor);
    }
    else{
        scene.background = texture;
    }
    animate()

    //循环渲染
    function animate() {
        requestAnimationFrame(animate);//请求再次执行函数animate
        directionalLight.position.copy(camera.position);
        // 计算屏幕中心的世界坐标
        const screenCenter = new THREE.Vector3(0, 0, 0); // 屏幕中心
        const worldCenter = screenCenter.clone().unproject(camera); // 使用 unproject 方法转换到世界坐标
        // 将平行光的 target 设置为计算出的世界坐标
        directionalLight.target.position.copy(worldCenter);
        directionalLight.target.updateMatrixWorld(); // 更新 target 的矩阵世界以反映位置的变化
        if (controls.enabled) {
            controls.update();
        }
        renderer.render(scene, camera)

        //渲染场景
        // renderer.render(scene, camera);
    }

    // 添加渐变背景
    // function createGradientBackground() {
    //     const planeGeometry = new THREE.PlaneGeometry(2, 2); // 创建全屏覆盖的矩形
    //     const shaderMaterial = new THREE.ShaderMaterial({
    //         uniforms: {
    //             topColor: { type: "vec3", value: new THREE.Color(0xd9eeff) },  // 渐变顶部颜色
    //             bottomColor: { type: "vec3", value: new THREE.Color(0xffffff) }, // 渐变底部颜色
    //         },
    //         vertexShader: `
    //             varying vec2 vUv;
    //             void main() {
    //                 vUv = uv;
    //                 gl_Position = vec4(position, 1.0);
    //             }
    //         `,
    //         fragmentShader: `
    //             uniform vec3 topColor;
    //             uniform vec3 bottomColor;
    //             varying vec2 vUv;
    //             void main() {
    //                 // 根据vUv的y进行线性插值实现渐变
    //                 gl_FragColor = vec4(mix(bottomColor, topColor, vUv.y), 1.0);
    //             }
    //         `,
    //         depthWrite: false,  // 防止遮挡
    //     });

    //     const gradientMesh = new THREE.Mesh(planeGeometry, shaderMaterial);
    //     gradientMesh.position.z = -5;
    //     // scene.add(gradientMesh);
    //     return gradientMesh;
    // }
    function createTextureBackground() {
        // 创建一个画布
        const canvas = document.createElement('canvas');
        canvas.width = window.innerWidth; // 宽度
        canvas.height = window.innerHeight; // 高度
        const ctx = canvas.getContext('2d');

        // 创建从上到下的线性渐变
        const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height); // 从顶部到底部
        gradient.addColorStop(0, '#aae6f9'); // 浅蓝色（顶部）
        gradient.addColorStop(1, '#65abf7'); // 深蓝色（底部）#65abf7

        // 填充画布
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // 将画布转换为纹理
        const texture = new THREE.CanvasTexture(canvas);
        return texture;
    }
    return { scene, camera, renderer, controls, directionalLight, texture }

}

export default Initialize