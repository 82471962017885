import { createRouter, createWebHashHistory } from "vue-router"
import Login from "./components/login.vue"
import Register from "./components/register.vue"
import Registertwo from "./components/registertwo.vue"
import Registerthree from "./components/registerthree.vue"
import Head from "./components/head.vue"
import ProjectList from "./components/projectList.vue"
import Projectitem from "./components/projectitem.vue"
import File from "./components/file.vue"
import Datacenter from "./components/datacenter.vue"
import Notice from "./components/notice.vue"
import Renwuinfo from "./components/renwuinfo.vue"
import Renwulist from "./components/renwulist.vue"
import Forget from "./components/forget.vue"
import Forgettwo from "./components/forgettwo.vue"
import Forgetthree from "./components/forgetthree.vue"
import member from './components/member.vue'
import sidemenu from './components/sidemenu.vue'
import bimviewer from './components/bimviewer.vue'
import docxviewer from "./components/fileview/docxviewer.vue"
import { getCookie } from './store/cookieUtil.js';
import excelviewer from "./components/fileview/excelviewer.vue"
import pdfviewer from "./components/fileview/pdfviewer.vue"
import pptxviewer from "./components/fileview/pptxviewer.vue"
import quality from "./components/quality.vue"
import Mine from "./components/mine.vue"
import dwgviewer from "./components/fileview/dwgviewer.vue"
// import projectBoard from "./components/projectBoard.vue"
import temp_projectboard from "./components/temp_projectboard.vue"
import webview from "./components/webview.vue"
import PersonalInformation from "./components/PersonalInformation.vue"
import UnitInformation from "./components/UnitInformation.vue"
import categoryRenwu from "./components/categoryRenwu.vue"
const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/",
            // redirect: '/bimviewer'
            redirect: '/login'
        },
        {
            path: '/webview',
            name: 'webview',
            component: webview
        },
        {
            path: "/bimviewer",
            name: 'bimviewer',
            component: bimviewer,
            meta: { requiresAuth: true },
        },
        {
            path: "/pptxviewer",
            name: 'pptxviewer',
            component: pptxviewer,
            meta: { requiresAuth: true },
        },
        {
            path: "/dwgviewer",
            name: 'dwgviewer',
            component: dwgviewer,
            meta: { requiresAuth: true },
        },
        {
            path: "/docxviewer",
            name: 'docxviewer',
            component: docxviewer,
            meta: { requiresAuth: true },
        },
        {
            path: "/excelviewer",
            name: 'excelviewer',
            component: excelviewer,
            meta: { requiresAuth: true },
        },
        {
            path: "/pdfviewer",
            name: 'pdfviewer',
            component: pdfviewer,
            meta: { requiresAuth: true },
        },
        {
            path: "/login",
            name: 'login',
            component: Login
        },
        {
            path: "/forget",
            name: 'forget',
            component: Forget,

        },
        {
            path: "/forgettwo",
            name: 'forgettwo',
            component: Forgettwo,

        },
        {
            path: "/forgetthree",
            name: 'forgetthree',
            component: Forgetthree,
        },
        {
            path: "/register",
            name: 'register',
            component: Register,
        },
        {
            path: "/registertwo",
            name: 'registertwo',
            component: Registertwo,
        },
        {
            path: "/registerthree",
            name: 'registerthree',
            component: Registerthree,

        },
        {
            path: "/head",
            name: 'head',
            component: Head,
            redirect: '/projectList',
            meta: { requiresAuth: true },
            children: [
                {
                    path: "/mine",
                    name: 'mine',
                    component: Mine,
                    meta: { requiresAuth: true },
                    redirect: '/PersonalInformation',
                    children: [
                        {
                            path: "/PersonalInformation",
                            name: 'PersonalInformation',
                            component: PersonalInformation,
                            meta: {
                                requiresAuth: true,
                   
                            },
                        },
                        {
                            path: "/UnitInformation",
                            name: 'UnitInformation',
                            component: UnitInformation,
                            meta: {
                                requiresAuth: true,
                               
                            },
                        }
                    ]
                },
                {
                    path: "/notice",
                    name: 'notice',
                    component: Notice,
                    meta: { requiresAuth: true },
                },
                {
                    path: "/projectList",
                    name: "projectList",
                    component: ProjectList,
                    meta: { requiresAuth: true },
                },
                {
                    path: "/sidemenu",
                    name: 'sidemenu',
                    component: sidemenu,
                    meta: { requiresAuth: true },
                    redirect: "/temp_projectboard",
                    children: [
                        {
                            path: "/categoryRenwu",
                            name: 'categoryRenwu',
                            component: categoryRenwu,
                            meta: { requiresAuth: true },
                            redirect: "/renwuinfo",
                            children: [
                                {
                                    path: "/renwuinfo",
                                    name: 'renwuinfo',
                                    component: Renwuinfo,
                                    redirect: 'renwulist',
                                    meta: { requiresAuth: true },
                                    children: [
                                        {
                                            name: 'renwulist',
                                            path: "/renwulist",
                                            component: Renwulist,
                                            meta: { requiresAuth: true },
                                        }
                                    ]
                                },
                            ]
                        },


                        {
                            path: "/file",
                            name: 'file',
                            component: File,
                            meta: { requiresAuth: true },
                        },
                        {
                            path: "/member",
                            name: 'member',
                            component: member,
                            meta: { requiresAuth: true },
                        },
                        {
                            path: "/quality",
                            name: 'quality',
                            component: quality,
                            meta: { requiresAuth: true },
                        },
                        {
                            path: "/temp_projectboard",
                            name: 'temp_projectboard',
                            component: temp_projectboard,
                            meta: { requiresAuth: true },
                        },

                    ]
                },
                {
                    path: "/datacenter",//数据中心
                    name: 'datacenter',
                    component: Datacenter,
                    meta: { requiresAuth: true },
                }

            ]
        },
        {
            path: "/projectitem",
            component: Projectitem,
            meta: { requiresAuth: true },
        },
    ]
});
// 路由守卫
router.beforeEach((to, from, next) => {
    const isAuthenticated = !!getCookie('token'); // 检查是否存在 token，替换为您的验证逻辑
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        // 如果路由需要身份验证且用户未登录
        next({ path: '/login' }); // 重定向到登录页
    } else {
        next(); // 继续导航
    }
});

export default router;