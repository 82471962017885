<template>
    <div class="sidemenu_body">
        <el-menu :default-active="side_default_active" class="el-menu-vertical-demo" text-color="#DDDDDD"
            :collapse="isCollapse" @select="side_select">

            <el-menu-item index="0" class="menu-item-centered" sidemenu_top>
                <img class="custom-icon" :src="require('@/img/board.svg')">
                <span>项目看板</span>
            </el-menu-item>
            <el-menu-item index="1" class="menu-item-centered ">
                <img class="custom-icon" :src="require('@/img/renwu.svg')">
                <span>任务管理</span>
            </el-menu-item>
            <el-menu-item index="2" class="menu-item-centered">
                <img class="custom-icon" :src="require('@/img/wendang2.svg')">
                <span>文档管理</span>
            </el-menu-item>
            <!-- <el-menu-item index="3" class="menu-item-centered">
                    <img class="custom-icon" :src="require('@/img/questionreport.svg')">
                    <span>问题报告</span>
                </el-menu-item>
                <el-menu-item index="3" class="menu-item-centered">
                    <img class="custom-icon" :src="require('@/img/moxing.svg')">
                    <span>模型管理</span>
                </el-menu-item>
                <el-menu-item index="4" class="menu-item-centered">
                    <img class="custom-icon" :src="require('@/img/chengben.svg')">
                    <span>成本管理</span>
                </el-menu-item>
                <el-menu-item index="5" class="menu-item-centered">
                    <img class="custom-icon" :src="require('@/img/zhiliang.svg')">
                    <span>质量管理</span>
                </el-menu-item>
                <el-menu-item index="6" class="menu-item-centered">
                    <img class="custom-icon" :src="require('@/img/process.svg')">
                    <span>进度管理</span>
                </el-menu-item>
                <el-menu-item index="7" class="menu-item-centered">
                    <img class="custom-icon" :src="require('@/img/device.svg')">
                    <span>设备管理</span>
                </el-menu-item> -->
            <el-menu-item index="8" class="menu-item-centered">
                <img class="custom-icon" :src="require('@/img/chengyuan.svg')">
                <span>成员管理</span>
            </el-menu-item>
            <div class="extraAreaData" v-if="!isCollapse">
                <el-button text type="default" class="listbutton" size="large" bg
                    @click="returnproject">项目列表</el-button>
                <div class="contain">
                    <div class="contain_inner" style="width:90%">
                        <div class="project_person">
                            用户数:{{ projectinfo.participant }}/{{ projectinfo.AllPersons }}人
                        </div>
                        <div class="contain_title">
                            容量:{{ UsedStorageCapacity }}G/{{ TotalStorageCapacity }}G
                        </div>
                        <el-progress :percentage="Number(containValue)">
                        </el-progress>
                    </div>


                </div>

            </div>
            <div class="activity_button">
                <el-image :src="activity_buttonUrl" @click="handleActivityClick"></el-image>
            </div>
        </el-menu>

        <div class="activityBody" :style="{ width: bodyWidth }">
            <div class="xmname">
                <div class="xmname_left"></div>

                <div class="xmname_right">
                    {{ projectinfo.CivilName }}
                </div>

            </div>
            <div class="router-view-container">
                <router-view></router-view>
            </div>
        </div>


    </div>


</template>
<script>
import '../css/sidemenu.css'
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, onBeforeUnmount, watch, computed } from 'vue'
import eventBus from '../js/eventBus.js';
import { useStore } from 'vuex'
export default {
    name: 'SideMenu',
    props: {
        msg: String
    },
}
</script>

<script setup>
const router = useRouter();
const route = useRoute();
const store = useStore()
const containValue = ref()
const isCollapse = ref(false);
const activity_buttonUrl = ref(require('@/img/collapse.svg'));
//菜单默认选择状态
const side_default_active = ref('0');
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
const UsedStorageCapacity = ref();
const TotalStorageCapacity = ref();
const bodyWidth = computed(() => {
    return isCollapse.value
        ? 'calc(100vw - var(--collapsed-width))'
        : 'calc(100vw - var(--expanded-width))';
});

// eslint-disable-next-line no-unused-vars
const side_select = (index, path) => {
    sessionStorage.setItem('menuid', index);
    side_default_active.value = index;
    switch (index) {
        case '0': router.push({
            name: "temp_projectboard"
        });
            break;
        case '1': router.push({
            name: "categoryRenwu"
        });
            break;
        case '2': router.push({
            name: "file"
        }
        ); break;
        case '3': router.push({
            name: "quality"
        }
        ); break;
        case '4': router.push({
            name: "quality"
        }
        ); break;
        case '5': router.push({
            name: "quality"
        }
        ); break;
        case '6': router.push({
            name: "quality"
        }
        ); break;
        case '8': router.push({
            name: "member"
        }
        ); break;

    }

}
const handleActivityClick = () => {
    if (isCollapse.value) {
        activity_buttonUrl.value = require('@/img/collapse.svg')
    }
    else {
        activity_buttonUrl.value = require('@/img/展开.svg')
    }
    isCollapse.value = !isCollapse.value
}
const routeTo_renwu = (id) => {
    side_default_active.value = '1';
    store.dispatch('renwu_idfun', id);
}
onBeforeUnmount(() => {
    eventBus.off('routeTo_renwu', routeTo_renwu);
})
watch(side_default_active, (newVal, oldVal) => {

    side_select(newVal); // 当 side_default_active 改变时，调用 side_select
});
// 刷新页面时，菜单项的选中状态不变
onMounted(() => {
    eventBus.on('routeTo_renwu', routeTo_renwu);
    if (route.query && route.query[0] != "t") {
        const id = route.query.id;
        if (id) {
            routeTo_renwu(id)
        }

    }
    if (sessionStorage.getItem('menuid') && route.query[0] != "t") {
        side_default_active.value = JSON.parse(sessionStorage.getItem('menuid'))
        const index = side_default_active.value
        if (index == 1) {
            router.push({
                name: "renwuinfo"
            })
        }
        else if (index == 2) {
            router.push({
                name: "file"
            }
            )

        }
        else if (index == 8) {
            router.push({
                name: "member"
            }
            )

        }
    }
    UsedStorageCapacity.value = (projectinfo.UsedStorageCapacity / 1024 / 1024 / 1024).toFixed(2);
    TotalStorageCapacity.value = (projectinfo.TotalStorageCapacity / 1024 / 1024 / 1024).toFixed(2);
    containValue.value = projectinfo.TotalStorageCapacity ? ((projectinfo.UsedStorageCapacity / projectinfo.TotalStorageCapacity) * 100).toFixed(2) : 0;
    const root = document.documentElement;
    root.style.setProperty('--expanded-width', `${window.innerWidth * (140 / 1920)}px`);
    root.style.setProperty('--collapsed-width', `${window.innerWidth * (64 / 1920)}px`);

})
// const returnproject = () => {
//     router.push({
//         name: "projectList"
//     })
// }
router.afterEach((to) => {
    switch (to.name) {
        case 'temp_projectboard': side_default_active.value = "0";
            break;
        case 'renwuinfo': side_default_active.value = "1";
            break;
        case 'file': side_default_active.value = "2"; break;
        case 'quality': side_default_active.value = "3"; break;
        case 'member': side_default_active.value = "8"; break;

    }
});

</script>