class IndexedDBManager {
    constructor(dbName = 'AppDatabase', dbVersion = 2) {
        this.dbName = dbName;
        this.dbVersion = dbVersion;
        this.db = null;
        this.initialize();
    }

    // 初始化数据库连接
    async initialize() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.dbVersion);

            request.onerror = (event) => {
                console.error('Database error:', event.target.error);
                reject(event.target.error);
            };

            request.onsuccess = (event) => {
                this.db = event.target.result;
                console.log('Database initialized');
                resolve();
            };

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains('keyValueStore')) {
                    // 优化对象存储结构
                    const store = db.createObjectStore('keyValueStore', {
                        keyPath: 'key' // 直接使用 key 作为主键
                    });
                    store.createIndex('value', 'value', { unique: false });
                }
            };
        });
    }

    // 安全添加/更新数据
    async setItem(key, value) {
        if (!this.db) await this.initialize();
        
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(['keyValueStore'], 'readwrite');
            const store = transaction.objectStore('keyValueStore');
            
            // 深度数据清洗
            const sanitizedValue = this.deepSanitize(value);
            
            const request = store.put({ 
                key, 
                value: sanitizedValue,
                timestamp: Date.now()
            });

            request.onsuccess = () => {
                console.log('Data saved successfully');
                resolve();
            }
            request.onerror = (event) => {
                console.error('Save error:', event.target.error);
                reject(event.target.error);
            };
        });
    }

    // 安全获取数据
    async getItem(key) {
        if (!this.db) await this.initialize();
        
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(['keyValueStore'], 'readonly');
            const store = transaction.objectStore('keyValueStore');
            const request = store.get(key);

            request.onsuccess = () => {
                const result = request.result?.value;
                resolve(result ? this.reviveData(result) : null);
            };
            
            request.onerror = (event) => {
                console.error('Read error:', event.target.error);
                reject(event.target.error);
            };
        });
    }

    // 深度数据清洗（处理不可序列化内容）
    deepSanitize(obj) {
        const seen = new WeakSet();
        
        const sanitizer = (item) => {
            if (item === null || typeof item !== 'object') return item;
            if (seen.has(item)) return '[Circular]';
            
            seen.add(item);
            
            if (Array.isArray(item)) {
                return item.map(sanitizer);
            }
            
            const cleanObj = {};
            for (const [k, v] of Object.entries(item)) {
                try {
                    // 过滤函数等不可序列化内容
                    if (typeof v !== 'function' && !(v instanceof HTMLElement)) {
                        cleanObj[k] = sanitizer(v);
                    }
                } catch (error) {
                    console.warn('Data sanitization warning:', error);
                    cleanObj[k] = '[Unserializable]';
                }
            }
            return cleanObj;
        };

        return sanitizer(obj);
    }

    // 数据恢复处理
    reviveData(data) {
        // 这里可以添加自定义反序列化逻辑
        // 例如转换 Date 对象等
        return JSON.parse(JSON.stringify(data), (key, value) => {
            if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T/.test(value)) {
                return new Date(value);
            }
            return value;
        });
    }

    // 删除数据
    async removeItem(key) {
        if (!this.db) await this.initialize();
        
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(['keyValueStore'], 'readwrite');
            const store = transaction.objectStore('keyValueStore');
            const request = store.delete(key);

            request.onsuccess = () => resolve();
            request.onerror = (event) => {
                console.error('Delete error:', event.target.error);
                reject(event.target.error);
            };
        });
    }

    // 清空存储
    async clear() {
        if (!this.db) await this.initialize();
        
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(['keyValueStore'], 'readwrite');
            const store = transaction.objectStore('keyValueStore');
            const request = store.clear();

            request.onsuccess = () => resolve();
            request.onerror = (event) => {
                console.error('Clear error:', event.target.error);
                reject(event.target.error);
            };
        });
    }
}
export default IndexedDBManager


