<template>
  <div class="categoryBody">
    <el-menu :default-active="defaultActiveIndex" class="el-menu-vertical-renwu" :router="true"
      @select="Titleside_select">
      <el-menu-item v-for="(item, index) in menuItems.arr" :key="index" :index="`/renwuinfo?title=${item._id}`"
        class="menu-item-centered">
        <div class="menu-item-content">
          <div class="TaskTitle_left" :style="{ 'border-left-color': rgbIntToHex(item.Color) }"></div>
          <div class="title_content">
            <div class="TaskTitle_right">{{ item.TaskTitle }}</div>
            <div class="TaskTitle_icon">
              <el-popover placement="bottom" trigger="click"
                :popper-style="{ minWidth: '20px', padding: '1', width: 'auto' }">
                <template #reference>
                  <el-image class="file_icon_style" :src="file_detail_icon" />
                </template>
                <template #default>
                  <div class="file_detail_icon_inner">
                    <div class="file_detail_a">
                      <el-button type="primary" text :icon="Edit" size="default" @click="editItem(item)">编辑</el-button>
                    </div>
                    <div class="file_detail_b">
                      <el-button type="danger" text :icon="Delete" size="default"
                        @click="deleteItem(item)">删除</el-button>
                    </div>
                  </div>
                </template>

              </el-popover>
            </div>
          </div>

        </div>
      </el-menu-item>
      <div class="addTile">
        <el-button type="primary" :icon="Search" @click="AddAssigmentTitleClick">添加标签</el-button>
      </div>
    </el-menu>
    <div class="categoryContent">
      <router-view></router-view>
    </div>
  </div>
  <el-dialog v-model="dialogFormVisible" title="编辑标签" @open="handleEdit">
    <el-form :model="Titleform">
      <el-form-item label="标签名称">
        <el-input v-model="Titleform.TaskTitle" autocomplete="off" />
      </el-form-item>
      <el-form-item label="标签颜色">
        <el-color-picker v-model="Titleform.Color" :predefine="predefineColors" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="AddAssigmentTitle">
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import "../css/categoryRenwu.css"
export default {

}
</script>
<script setup>
import { ref, onMounted, getCurrentInstance, reactive } from "vue";
import { Edit, Delete } from '@element-plus/icons-vue';
import handleError from '../config/HandleError.js'
import { getCookie } from '../store/cookieUtil.js';
import StorageUtil from '../config/storageUtil.js'
import { useStore } from "vuex";
import { ElMessageBox } from 'element-plus'
// const showDeleteButton = reactive([])
const store = useStore()
const { proxy } = getCurrentInstance()
const user = StorageUtil.getUserFromSession()
const projectinfo = JSON.parse(sessionStorage.getItem('projectinfo'))
const menuItems = reactive({
  arr: []
})
const predefineColors = ref([
  "#FF0000",
  "#FFC0CB",
  "#B22222",
  "#800000",
  "#FFB6C1",
  "#0000FF",
  "#87CEEB",
  "#00008B",
  "#191970",
  "#4169E1",
  "#00FF00",
  "#7CFC00",
  "#006400",
  "#808000",
  "#3CB371",
  "#FFFF00",
  "#FFD700",
  "#FFF700",
  "#DEB887",
  "#808000",
  "#800080",
  "#D8BFD8",
  "#4B0082",
  "#800080",
  "#E6E6FA",
  "#FFA500",
  "#FF8C00",
  "#FF7F50",
  "#FF7518",
  "#FFA07A",
  "#FF69B4",
  "#FFC0CB",
  "#FF1493",
  "#FFB7C5",
  "#FFB7D1",
  "#C0C0C0",
  "#A9A9A9",
  "#696969",
  "#E0E0E0",
  "#36454F"
])
const file_detail_icon = ref(require('@/img/detail_rw.svg'))
const defaultActiveIndex = ref('/renwuinfo')
const Titleform = reactive({
  TaskTitle: "", Color: "#409EFF"
})
const dialogFormVisible = ref(false)
onMounted(async () => {
  await refreshTitle()
  if (menuItems.arr.length > 0) {
    const title = {
      id: menuItems.arr[0]._id, name: menuItems.arr[0].TaskTitle
    }
    store.dispatch('titleIdfun', title)
    defaultActiveIndex.value = `/renwuinfo?title=${menuItems.arr[0]._id}`
  }
});
const handleEdit = () => {
  if (titleItem) {
    Titleform.TaskTitle = titleItem.TaskTitle;
    Titleform.Color = rgbIntToHex(titleItem.Color)
  }

}
const refreshTitle = async () => {
  const url_data = new URLSearchParams();
  url_data.append('Token', getCookie('token'));
  url_data.append('Account', user.emailname);
  url_data.append('ProjectId', projectinfo._id);
  await handleRequest(proxy.$urls.m().GetAssigmentTitle, url_data, async (data) => {
    menuItems.arr = data.ProjectAssignmentTitleProto
    if (menuItems.arr.length == 1) {
      defaultActiveIndex.value = `/renwuinfo?title=${menuItems.arr[0]._id}`
      const selectedItem = menuItems.arr[0]
      const title = {
        id: selectedItem._id, name: selectedItem.TaskTitle
      }
      store.dispatch('titleIdfun', title)
    }
    else if (menuItems.arr.length == 0) {
      store.dispatch('titleIdfun', null)
    }
  });
}
const Titleside_select = (e) => {
  // 使用 URLSearchParams 解析查询参数
  const urlParams = new URLSearchParams(e.split('?')[1]); // 获取查询参数部分
  const titleValue = urlParams.get('title'); // 获取 title 参数的值
  const selectedItem = menuItems.arr.find(item => item._id == titleValue)
  const title = {
    id
      : selectedItem._id, name: selectedItem.TaskTitle
  }
  store.dispatch('titleIdfun', title)
};
const deleteItem = (item) => {
  ElMessageBox.confirm(
    '确认删除此标签?',
    '警告',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async () => {
      const url_data = new URLSearchParams();
      url_data.append('Token', getCookie('token'));
      url_data.append('Account', user.emailname);
      url_data.append('ProjectId', projectinfo._id);
      url_data.append('ProjectAssignmentTitleId', item._id);
      await handleRequest(proxy.$urls.m().DeleteAssigmentTitle, url_data, async (data) => {
        if (item._id == store.state.title.id) {
          store.dispatch('titleIdfun', null)
        }
       
        new proxy.$tips("删除成功", "success").Mess_age();
        await refreshTitle()

      });
    })

}
const AddAssigmentTitle = async () => {
  if (titleItem) {
    const url_data = new URLSearchParams();
    url_data.append('Token', getCookie('token'));
    url_data.append('Account', user.emailname);
    url_data.append('ProjectId', projectinfo._id);
    url_data.append('TaskTitle', Titleform.TaskTitle);
    url_data.append('ProjectAssignmentTitleId', titleItem._id);
    url_data.append('Color', hexToRgbInt(Titleform.Color));
    await handleRequest(proxy.$urls.m().ModifyAssigmentTitle, url_data, async (data) => {
      new proxy.$tips("保存成功", "success").Mess_age();
      dialogFormVisible.value = false;
      await refreshTitle()

    });
  }
  else {
    const url_data = new URLSearchParams();
    url_data.append('Token', getCookie('token'));
    url_data.append('Account', user.emailname);
    url_data.append('ProjectId', projectinfo._id);
    url_data.append('TaskTitle', Titleform.TaskTitle);
    url_data.append('Color', hexToRgbInt(Titleform.Color));
    await handleRequest(proxy.$urls.m().AddAssigmentTitle, url_data, async (data) => {
      new proxy.$tips("保存成功", "success").Mess_age();
      dialogFormVisible.value = false;
      await refreshTitle()

    });
  }

}
let titleItem = null; // 用于保存要操作的项的引用
const editItem = (item) => {
  titleItem = item
  dialogFormVisible.value = true
}
const AddAssigmentTitleClick = () => {
  dialogFormVisible.value = true;
  titleItem = null
}

const handleRequest = async (url, params, successCallback) => {
  try {
    const res = await new proxy.$request(url, params).modepost();
    if (!res) return;

    if (res.status !== 200) {
      new proxy.$tips(res.data.message, "warning").Mess_age();
      return;
    }

    if (res.data && res.data.Error === 0) {
      successCallback(res.data);
    } else {
      const errorMessage = handleError(res);
      new proxy.$tips(errorMessage, "error").Mess_age();
    }
  } catch (error) {
    console.error("请求失败:", error);
    // new proxy.$tips("请求失败，请稍后重试", "error").Mess_age();
  }
};
// 将十六进制颜色值转换为纯数字
const hexToRgbInt = (hex) => {
  const hexValue = hex.replace("#", "");
  const rgbInt = parseInt(hexValue, 16);
  return rgbInt;
}

// 将纯数字转换回十六进制颜色值
const rgbIntToHex = (rgbInt) => {
  // 将整数转换为十六进制字符串
  const hexString = rgbInt.toString(16).padStart(6, "0");
  return `#${hexString.toUpperCase()}`;
}
</script>