<template>
  <div class="dwg-viewer-container">
    <!-- 控制栏 -->
    <div class="control-bar">
      <input type="file" @change="handleFileUpload" ref="fileInput" />
    </div>

    <!-- 渲染区域 -->
    <div class="render-area">
      <canvas ref="canvasElement"></canvas>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DwgViewer',
  props: {
    msg: String
  },

}
</script>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'

// WASM 模块实例 
let wasmModule = null
const initWasm = async () => {
  const module = await import('/public/wasm/dwg.js')
  console.log(module);
  // wasmModule = await module.default({
  //   locateFile: (path) => `/wasm/$$${path}`,
  //   onizedRuntimeInitial: () => console.log('WASM Runtime Ready'),
  //   printErr: (err) => console.error('[DWG Error]', err)
  // })
}

// 组件状态 
const canvasElement = ref(null)
const fileInput = ref(null)
const isLoading = ref(false)
const loadProgress = ref(0)

// 生命周期 
onMounted(async () => {
  initCanvas(1280, 720)
})

onBeforeUnmount(() => {
  if (wasmModule) {
    wasmModule._dwg_free(currentDwgPtr.value)
  }
})

// 画布初始化 
const initCanvas = (width, height) => {
  const canvas = canvasElement.value;
  canvas.width = width
  canvas.height = height
  canvas.style.cursor = 'grab'
}


const handleFileUpload = async (event) => {
  const file = event.target.files[0]
  isLoading.value = true
  try {
    await initWasm()
    const arrayBuffer = await readFileWithProgress(file)
    await renderDwgFile(arrayBuffer)
  } catch (error) {
    console.error('文件解析失败:', error)
  } finally {
    isLoading.value = false
  }
}

const readFileWithProgress = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onprogress = (e) => {
      loadProgress.value = (e.loaded / e.total) * 100
    }
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

// DWG 解析核心 
const currentDwgPtr = ref(null)
const renderDwgFile = async (buffer) => {
  const data = new Uint8Array(buffer)
  const ptr = wasmModule._malloc(data.length)
  wasmModule.HEAPU8.set(data, ptr)

  currentDwgPtr.value = wasmModule.ccall(
    'dwg_read_file',
    'number',
    ['number', 'number'],
    [ptr, data.length]
  )

  wasmModule._free(ptr)
}



</script>

<style scoped>
/* .dwg-viewer-container  {
     border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

    .control-bar {
      padding: 12px 16px;
    background: #f8f9fa;
    display:;
    flex  align-items: center;
    gap: 12px;
    border-bottom: 1px solid #eee;
}

    .btn-primary {
      padding: 8px 16px;
    background: #2196f3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor pointer:;
    transition : background 0.2s;

    &:hover {
      background: #1976d2;
  }

    .icon {
      margin - right: 6px;
  }
}

    .zoom-controls {
      display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto btn .;

    -icon {
      padding: 6px 10px;
    background: #fff;
    border: 1px # solidddd;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background: #f5f5f5;
    }
  }

    .-displayscale {
      min - width: 60px;
    text-align: center;
    color #666:;
  }
}

    .render-area {
      position: relative;
    height: 600px;
    background: repeating-conic-gradient(#f0f0f0 0% 25%, white 0% 50%)
    50% / 20 20pxpx;

    canvas {
      display: block;
    width: 100%;
    height: 100%;
  }
}

    .loading-overlay {
      position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255 , 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;

    .progress-container {
      width: 200px;
    background: #f0f0f0;
    border-radius: 8px;
    padding: 4px;
  }

    .progress-bar {
      height: 8px;
    background: #2196f3;
    border-radius: 4px;
    transition: width 0.3s ease;
 }

    progress .-text {
      margin - top: 8px;
    text-align: center;
    color: #666;
    font-size: 0.9em;
  }
} */
</style>