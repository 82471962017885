import axios from 'axios'
import { Base64 } from 'js-base64'
import { ElMessageBox, ElMessage } from 'element-plus'
import router from '@/router';
import { getCookie, eraseCookie } from '../store/cookieUtil.js';
//创建通用axios配置
// let instance =axios.create({
// responseType:"json",
// headers:{
//     'Content-Type':'application/json'
// }
// })
let instance = axios.create({
    // responseType: "json",
    headers: {
        // 'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})
let TokenInvalidFlag = 1;
//对token加密
function baseFun() {
    const token = getCookie('token');
    const base64 = Base64.encode(token + ':')
    return 'Basic ' + base64

}
//http拦截：在axios请求发出之前给每一个接口携带token去后端验证身份
instance.interceptors.request.use(
    config => {
        let token = getCookie('token');//将token缓存在本地
        if (token) {
            config.headers.Authorization = baseFun()
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)
//http拦截：在axios请求发出之后
instance.interceptors.response.use(
    response => {
        if (response.data.Error == 200015) {
            if (TokenInvalidFlag) {
                TokenInvalidFlag = 0;
                ElMessageBox.alert('登录状态已失效，请重新登录', '提示', {
                    confirmButtonText: '重新登录',
                    type: 'error'
                }).then(() => {
                    eraseCookie('token');
                    router.push('/login');
                }).catch(err => {
                    console.error("登录失效提示框错误:", err);
                });
            }

        } else {
            TokenInvalidFlag = 1;
            return response
        }

    },
    error => {
        if (error.response) {
            let statusCode = error.response.status;   // HTTP 状态码
            let errorMsg = error.response.data.msg || '服务器繁忙,请稍后再试';
            switch (statusCode) {
                case 401:
                default:
                    ElMessage({
                        message: errorMsg || `发生服务器错误，错误码：${statusCode}`,
                        type: 'error',
                        duration: 5000
                    });
                    break;
            }
        }
        return Promise.reject(error.response.data)//返回接口错误信息
    }
)
export default instance