<template>

    <el-collapse v-model="activeNames" @change="handleChange_collapse" class="rw_out" v-if="renwuinfo.arr.length">
        <el-collapse-item v-for="(item, index) in renwuinfo.arr" :name="item._id" :key="index">
            <template #title>
                <div :class="['title-bg', !item.status ?(isOverdue(item) ? 'overdue' :
                     'in-progress') : 'completed']"
                    >
                    <div class="renwuseq">{{ item.TaskCode }}</div>
                    <div class="renwumc">{{ item.TaskName }}</div>
                    <!-- <img src="../img/editxm.svg" class="edit-icon" alt=""> -->
                    <el-icon color="#FFFFFF" class="edit-icon" @click.stop="editrenwuplus($event, item)"
                        v-if="!item.status">
                        <Edit />
                    </el-icon>
                    <!-- <el-image class="edit-icon_delete" :src="deleterw"
                        @click.stop="deleterenwuplus($event, item._id)"></el-image> -->
                    <div class="jxz" v-if="!item.status">进行中</div>
                    <div class="jxz" v-else>已完成</div>
                    <el-icon class="item_icon-left">
                        <template v-if="activeNames.includes(item._id)">
                            <CaretBottom />
                        </template>
                        <template v-else>
                            <CaretRight />
                        </template>
                    </el-icon>

                </div>
            </template>
            <div class="time">
                <div class="timeLeft">
                    <div class="fbtime"> 发布时间 :&nbsp;&nbsp;{{ formatDate(item.createdTime) }}</div>
                    <div class="fbz">发布者 :&nbsp;&nbsp;{{ item.creatorName }}</div>
                    <div class="jsz">
                        <el-dropdown>
                            <span class="el-dropdown-link">
                                接收者 :&nbsp;&nbsp;{{ item.accountinfos[0] }}
                                <el-icon class="el-icon--right">
                                    <arrow-down />
                                </el-icon>
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item v-for="(account, index) in item.accountinfos" :key="index">
                                        {{ account }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>

                    </div>
                </div>
                <div class="timeRight">
                    <div class="jztime">截止时间 :{{ formatDate(item.deadline) }}</div>
                    <div class="operation">
                        <el-dropdown placement="bottom" trigger="click">
                            <img src="../img/detail_rw.svg">
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item @click="deleterenwuplus($event, item._id)">删除任务</el-dropdown-item>
                                    <el-dropdown-item @click="finishAssignment(item)"
                                        v-if="!item.status">完成任务</el-dropdown-item>

                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </div>

            </div>
            <div class="rwdescribe">
                <div class="rwdescribe_text">任务内容：</div>
                <div class="rwdescribe_content">{{ item.description }}</div>
            </div>
            <el-row class="item_filed">
                <el-col :span="4" v-for="(file, index) in item.FileInfos" :key="index" :offset="1">
                    <div class="rwitem_upload" @dblclick="previewFile(file)">
                        <div class="rwitem_submited" :title="file.FileName">
                            <img :src="getImageSrc(file.FileType)">
                            <label>{{ file.FileName }}.{{ getFileTypeByValue(file.FileType).toLowerCase() }}</label>
                        </div>
                        <img src="../img/fjdownload.svg" @click.stop="downloadClick(file)">
                        <img src="../img/fjdelete.svg" v-if="isUserInAccountInfos(item) && !item.status"
                            @click.stop="deleteClick(item, file)">
                    </div>
                    <div class="progressBottom">
                        <el-progress :percentage="file.progress" :stroke-width="3" :show-text="false"
                            v-if="file.progress > 0 && file.progress < 100"></el-progress>
                    </div>
                </el-col>
            </el-row>
            <div class="inner2">
                <div class="upload_release" v-if="isUserInAccountInfos(item) && !item.status">
                    <el-upload class="upload-demo__release" action="#"
                        :http-request="(uploadFile) => fileupload(uploadFile, item)" :show-file-list="false"
                        :before-upload="beforeUpload" multiple :limit="9">
                        <el-button class="fujian">上传附件</el-button>
                    </el-upload>
                    <!-- 附件上传预览 -->
                    <!-- <el-row class="item_el_row__release">
                        <el-col :span="4" v-for="(file, index) in item.FileInfos" :key="index" :offset="1">
                            <div class="rwitem_upload">
                                <div class="rwitem_submited">
                                    <img :src="getImageSrc(file.FileType)">
                                    <label>{{ file.FileName }}</label>
                                </div>
                                <img src="../img/fjdownload.svg" @click="downloadClick(file)">
                                <img src="../img/fjdelete.svg">
                            </div>
                        </el-col>
                    </el-row> -->
                </div>
                <div class="input_reply" v-if="!item.status && !isUserInAccountInfos(item) && ValidReply(item)">
                    <el-input v-model="input3" size="small" placeholder="回复" :prefix-icon="EditPen"
                        class="input_reply_inner" @focus="handleClickreply(item._id)" ref="inputref" />
                </div>
                <el-table :data="item.Submissioninfo" style="width: 100%" class="datatable">
                    <!-- align="center" -->
                    <el-table-column fixed prop="UserAccount" label="接收者" :width="getDynamicWidth(200)"
                        align="center" />
                    <el-table-column prop="Comments" label="回复" :width="getDynamicWidth(525)" align="center"
                        show-overflow-tooltip />
                    <el-table-column prop="SubmissionTime" label="提交时间" :width="getDynamicWidth(300)" align="center">
                        <template #default="{ row }">
                            <span>
                                {{ formatDate(row.SubmissionTime) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fileInfos" label="提交成果" :width="getDynamicWidth(500)" align="center">
                        <template #default="{ row }">
                            <div class="upload_container" v-if="row.files && row.files.length !== 0">
                                <div class="uploadCol">
                                    <el-col :span="1" v-for="file in row.files" :key="file.FileId" :offset="1"
                                        :justify="'center'" class="fileinfo_col">
                                        <div class="submitting" @dblclick="previewFile(file)">
                                            <div class="submited" :title="file.FileName"
                                                @mouseenter="showDeleteButton[file.FileId] = true"
                                                @mouseleave="showDeleteButton[file.FileId] = false">
                                                <img :src="getImageSrc(file.FileType)">
                                                <label>{{ file.FileName }}.{{
                                                    getFileTypeByValue(file.FileType).toLowerCase()
                                                }}</label>
                                                <el-icon
                                                    v-if="ValidReply(item) && showDeleteButton[file.FileId] && !isUserInAccountInfos(item)"
                                                    @click="deleteSubmissionFile(item, row, file)">
                                                    <Delete />
                                                </el-icon>
                                            </div>
                                            <div class="re" v-if="isUserInAccountInfos(item)">
                                                <el-button link type="primary"
                                                    @click.stop="downloadClick(file)">下载</el-button>
                                            </div>
                                            <!-- <div class="ac" v-else>
                                                <div class="validdiv" v-if="ValidReply(item)&&showDeleteButton">
                                                    <el-button link type="danger"
                                                        @click="deleteSubmissionFile(item, row, file)">删除</el-button>
                                                </div>

                                            </div> -->
                                        </div>
                                        <div class="progressBottom">
                                            <el-progress :percentage="file.progress" :stroke-width="3"
                                                :show-text="false"
                                                v-if="file.progress > 0 && file.progress < 100"></el-progress>
                                        </div>
                                    </el-col>
                                </div>

                                <el-upload class="upload-demo__release" action="#"
                                    :http-request="(uploadFile) => Submission_fileupload(uploadFile, item, row)"
                                    :show-file-list="false" :before-upload="beforeUpload" multiple :limit="9"
                                    v-if="ValidReply(item) && !isUserInAccountInfos(item)">
                                    <el-button link type="primary">上传</el-button>
                                </el-upload>
                            </div>

                            <div
                                v-if="row.files && row.files.length === 0 && !isUserInAccountInfos(item) && ValidReply(item)">
                                <el-upload class="upload-demo__release" action="#"
                                    :http-request="(uploadFile) => Submission_fileupload(uploadFile, item, row)"
                                    :show-file-list="false" :before-upload="beforeUpload" multiple :limit="9">
                                    <el-button link type="primary">上传</el-button>
                                </el-upload>
                            </div>
                        </template>

                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center">
                        <template #default="{ row }">
                            <div v-if="isUserInAccountInfos(item) && !item.status && row.Status == 0">
                                <el-button link type="primary" @click="finishhandleClick(row, item)">完成</el-button>
                                <el-button link type="danger" @click="rejectHandleClick(row, item)">驳回</el-button>
                            </div>
                            <div
                                v-else-if="!item.status && !isUserInAccountInfos(item) && ValidReply(item) && !row.blank">
                                <el-button link type="danger" @click="handleClickDelete(item, row)">删除</el-button>
                            </div>

                        </template>
                    </el-table-column>
                </el-table>
            </div>

        </el-collapse-item>
    </el-collapse>
    <el-empty description="暂无任务" v-else class="empty_outter" :image="emptyimageurl" />
    <el-dialog v-model="editrenwu" title="编辑任务" venter center round @opened="loadData" :close-on-click-modal="false">
        <el-form :model="rwform" ref="rweditRef" :rules="rules">
            <el-form-item label="任务描述" :label-width="formLabelWidth" required prop="describe">
                <el-input v-model="rwform.describe" placeholder="请输入任务描述" />
            </el-form-item>
            <el-form-item label="项目成员" :label-width="formLabelWidth" required>
                <el-select v-model="rwform.chengyuan" placeholder="请选择项目成员" multiple>
                    <template #header>
                        <el-checkbox v-model="checkAll" :indeterminate="indeterminate" @change="handleCheckAll">
                            All
                        </el-checkbox>
                    </template>
                    <el-option v-for="item in xmcyoptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="上传文件" :label-width="formLabelWidth" class="upload_release_label">
                <div class="edit_load_out">
                    <div class="outtop">
                        <el-row class="item_filed_load">
                            <el-col :span="12" v-for="file in rwform.fileList_load" :key="file.FileId" :offset="0">
                                <div class="rwitem_upload">
                                    <div class="rwitem_submited">
                                        <img :src="getImageSrc(file.FileType)">
                                        <label>{{ file.FileName }}</label>
                                    </div>
                                    <img src="../img/fjdelete.svg" v-if="isUserInAccountInfos(rwform)"
                                        @click="removeFile(file.FileId)" style="cursor: pointer;">
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="outbottom">
                        <el-upload v-model:file-list="fileList_edit" class="rwupload-demo" action="#" multiple
                            :limit="9" :auto-upload="false" :on-change="beforeUpload">
                            <!-- <el-button type="primary">Click to upload</el-button> -->
                            <el-button class="wenjian"><el-icon>
                                    <Plus />
                                </el-icon>选择文件</el-button>
                            <!-- <template #tip>
                        <div class="rwel-upload__tip">
                            jpg/png files with a size less than 500KB.
                        </div>
                    </template> -->
                        </el-upload>
                    </div>


                </div>

            </el-form-item>
            <el-form-item label="截止日期" required prop="jzdate">
                <el-date-picker v-model="rwform.jzdate" type="datetime" placeholder="请选择" style="width: 100%" />
            </el-form-item>

        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="editrenwuclick" :loading="button_loading">保存</el-button>
                <el-button @click="editrenwucancel">
                    取消
                </el-button>
            </div>
        </template>
    </el-dialog>
    <el-dialog v-model="replycontent" title="回复" width="500" venter center round :close-on-click-modal="false"
        @close="replyClose">
        <el-form :model="replyform">
            <el-form-item label="文字回复" :label-width="formLabelWidth">
                <el-input v-model="replyform.describe" maxlength="500" style="width: 440px" placeholder="请输入"
                    show-word-limit type="textarea" />
            </el-form-item>

            <el-form-item label="上传文件" :label-width="formLabelWidth">
                <el-upload v-model:file-list="fileList_reply" class="rwupload-demo" action="#" multiple :limit="9"
                    :auto-upload="false">
                    <!-- <el-button type="primary">Click to upload</el-button> -->
                    <el-button class="wenjian"><el-icon>
                            <Plus />
                        </el-icon>选择文件</el-button>
                    <!-- <template #tip>
                        <div class="rwel-upload__tip">
                            jpg/png files with a size less than 500KB.
                        </div>
                    </template> -->
                </el-upload>
            </el-form-item>


        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="replyrenwuclick" :loading="button_loading">确定</el-button>
                <el-button @click="replyrenwucancel">
                    取消
                </el-button>
            </div>
        </template>
    </el-dialog>
    <el-dialog v-model="previewdialog" :close-on-click-modal="false" class="video-dialog" destroy-on-close="true">
        <video controls>
            <source :src="videoUrl" type="video/mp4" class="video-player">
        </video>
    </el-dialog>
</template>
<script>
import "../css/renwuitem.css"

export default {
    name: 'RenwuItem',


}
</script>
<script setup>
import { ref, reactive, computed, watch, getCurrentInstance, onMounted, onBeforeUnmount, nextTick, watchEffect } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { EditPen } from '@element-plus/icons-vue'
import { useStore } from 'vuex'
import { formatDate } from "../config/formatDate.js"
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import eventBus from '../js/eventBus.js';
import Client from '@/js/Client.js';
import { preview } from 'vue3-image-preview';
import { getFileType, getFileTypeByValue, getImageSrc, navigateBasedOnFileType, FileType } from '@/config/filetype.js';
import filepath from '@/config/filePath.js';
import StorageUtil from '../config/storageUtil.js'
const user = StorageUtil.getUserFromSession()
// import blobToBytes from '../js/blobToBytes.js'
const { proxy } = getCurrentInstance()
const store = useStore()
const editrenwu = ref(false)
const activeNames = ref("1");
const emptyimageurl = require('@/img/暂无任务.svg')
// const deleterw = ref(require("../img/deleterw.svg"))
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
const xmcyoptions = reactive([]);
const checkAll = ref(false)
const indeterminate = ref(false)
const replycontent = ref(false)
const previewdialog = ref(false)
const videoUrl = ref(null)
const showDeleteButton = reactive([]) // 初始化为一个空数组 
const replyform = reactive(
    {
        describe: "",
        fileList: ""
    }
)
const inputref = ref(null)
const fileList_reply = ref([])
const button_loading = ref(false)
const getDynamicWidth = (px) => {
    // 根据窗口的宽度动态计算列宽
    const ratio = window.innerWidth / 2560; // 假设 1920 是你设计的基准宽度
    return Math.max(px * ratio, 10) + 'px'; // 保证最小宽度不小于原始值的 80%
}
const handleChange_collapse = (tab) => {
    sessionStorage.setItem('activeCollapseItems', JSON.stringify(tab));
}
const ValidReply = (item) => {
    const index = item.accountinfos.findIndex(i => i === user.emailname);
    if (index === -1) return false
    return true
}
let updateInterval = null
const now = ref(new Date())
onMounted(() => {
    eventBus.on('collapse', CollapseClick);
    eventBus.on('sort', sortlist);
    eventBus.on('sort_reverse', sort_reverse);
    eventBus.on('scrollView', scrollView);
    updateInterval = setInterval(() => {
        now.value = new Date();
    }, 30000); // 每半分钟更新一次
})
onBeforeUnmount(() => {
    // 在组件销毁时取消事件监听
    eventBus.off('collapse', CollapseClick);
    eventBus.off('sort', sortlist);
    eventBus.off('sort_reverse', sort_reverse);
    eventBus.off('scrollView', scrollView);
    clearInterval(updateInterval);
});
const isOverdue = (item) => {
    const deadline = new Date(item.deadline);
    return now.value >= deadline;
}
const CollapseClick = (flag) => {
    //展开
    if (flag) {
        activeNames.value = renwuinfo.arr.map(item => item._id);
    }
    //折叠
    else {
        activeNames.value = []
    }
}
const replyClose = () => {
    replyform.describe = ""
    fileList_reply.value = [];
    button_loading.value = false;
    //让回复的输入框失去焦点
    inputref.value[0].blur()
}
const handleClickDelete = (item, row) => {
    ElMessageBox.confirm(
        '此操作将永久删除该回复，是否继续？',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            await deleteReply(item, row)
        })
        .catch(() => {
            ElMessage({
                type: 'info',
            })
        })
}
const deleteReply = async (item, row) => {
    const urldata = new URLSearchParams();
    urldata.append('Token', getCookie('token'));
    urldata.append('Account', user.emailname);
    urldata.append('SubmissionId', row._id);
    urldata.append('TaskId', item._id);
    const res = await new proxy.$request(proxy.$urls.m().DeleteSubmission, urldata).modepost();
    if (!res || res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            new proxy.$tips('已删除', "success").Mess_age()
            const task = renwuinfo.arr.find((i) => i._id === item._id);
            if (task) {
                const submissionIndex = task.Submissioninfo.findIndex((i) => i._id === row._id);
                if (submissionIndex !== -1) {
                    task.Submissioninfo.splice(submissionIndex, 1);
                    if (task.Submissioninfo.length == 0) {
                        AddUserItem(task)
                    }
                }

            }
            // eventBus.emit('taskrefresh');
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()
        }
    }
}
// const AddUserItem = (task, flag) => {
//     //true,添加操作，去除空白
//     //false,删除操作，添加空白
//     task.accountinfos.forEach(account => {
//         if (!flag) {
//             const userIndex = task.Submissioninfo.findIndex((i) => i.UserAccount === account);
//             if (userIndex == -1) {
//                 //若没有该用户,就新建一条空白回复
//                 task.Submissioninfo.push({
//                     UserAccount: account,
//                     Comments: "——", // 默认评论为空
//                     SubmissionTime: null, // 默认提交时间为空
//                     fileInfos: [] // 默认文件数组为空
//                 });
//             }
//         }
//         else {
//             const userIndex = task.Submissioninfo.filter((i) => i.UserAccount === account);
//             if (userIndex == -1) {
//                 //若没有该用户,就新建一条空白回复
//                 task.Submissioninfo.push({
//                     UserAccount: account,
//                     Comments: "——", // 默认评论为空
//                     SubmissionTime: null, // 默认提交时间为空
//                     fileInfos: [] // 默认文件数组为空
//                 });
//             }
//         }

//     });
// }
//处理数据
const AddUserItem = (task) => {
    task.accountinfos.forEach(account => {
        const userIndex = task.Submissioninfo.findIndex((i) => i.UserAccount === account);
        if (userIndex == -1) {
            //若没有该用户,就新建一条空白回复
            task.Submissioninfo.push({
                UserAccount: account,
                Comments: "——", // 默认评论为空
                SubmissionTime: null, // 默认提交时间为空
                fileInfos: [],// 默认文件数组为空
                blank: true
            });
        }
    });
}
const deleteSubmissionFile = (items, row, file) => {
    ElMessageBox.confirm(
        '此操作将永久删除该文件，是否继续？',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            await Submission_fileupload(null, items, row, file)

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消删除'
            });
        });

}
const fileupload = async (event, item) => {
    filelist.length = 0;
    for (const item of item.FileInfos) {
        if (item.FileId.toString().length === 16) {
            filelist.push(item.FileId)
        }
    }
    const nameWithoutExtension = event.file.name.replace(/\.\w+$/, "");
    const typeNum = getFileType(event.file.name.split('.').pop().toLowerCase())
    const tempFileId = Date.now()
    const temp_file = {
        FileName: nameWithoutExtension,
        FileType: typeNum,
        FileId: tempFileId,
        temp: true,
        progress: 0,
    }
    item.FileInfos.push(temp_file)
    //找到临时上传文件索引
    const i = item.FileInfos.findIndex(file => file.FileId === tempFileId);
    if (i == -1) return
    const response = await handleFileChange(event.file, item.FileInfos, i)
    if (!response) {
        new proxy.$tips('上传失败', "error").Mess_age()
    }
    const urldata = new URLSearchParams();
    urldata.append('Token', getCookie('token'));
    urldata.append('Account', user.emailname);
    urldata.append('ProjectId', projectinfo._id);
    urldata.append('ProjectAssignmentId', item._id);
    urldata.append('accountinfos', item.accountinfos);
    urldata.append('Description', item.description);
    urldata.append('deadline', item.deadline);
    urldata.append('FileInfos', filelist);
    urldata.append('TaskTitleId', title.value.id);
    const res = await new proxy.$request(proxy.$urls.m().ModifyAssigment, urldata).modepost();
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        button_loading.value = false;
        return;

    }
    else {
        if (res.data && res.data.Error == 0) {
            // const taskindex = renwuinfo.arr.findIndex((i) => i._id === item._id);
            // renwuinfo.arr[taskindex] = res.data.ProjectTaskinfo;
            console.log(i, response)
            item.FileInfos[i].FileId = response.FileShareInfo._id;
            item.FileInfos[i].FileSize = response.FileShareInfo.FileSize;
            item.FileInfos[i].FileName = response.FileShareInfo.FileName;
            item.FileInfos[i].FileType = response.FileShareInfo.FileType;
            item.FileInfos[i].filtPath = response.FileShareInfo.OssBucketPath;
            item.FileInfos[i].FolderId = response.FileShareInfo.FolderId;
            item.FileInfos[i].temp = false;
            // eventBus.emit('taskrefresh');
            new proxy.$tips('保存成功', "success").Mess_age()
            editrenwu.value = false;
            fileList_edit.value = []
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()
        }
        // const tempIndex = item.fileInfos.findIndex(fileInfo => fileInfo.FileId === tempFileId);
        // if (tempIndex !== -1) {
        //     item.fileInfos.slice(tempIndex, 1);
        // }
    }
}
const Submission_fileupload = async (event = null, items, row, file = null) => {
    filelist.length = 0;
    let i = null;
    let response = null;
    //删除文件
    if (event == null && file != null) {
        //去除文件id
        for (const item of row.files) {
            if (item.FileId != file.FileId)
                filelist.push(item.FileId)
        }
    }
    //添加文件
    else {
        for (const item of row.files) {
            //区分临时id和文件id
            if (item.FileId.toString().length === 16) {
                filelist.push(item.FileId)
            }
        }
        const nameWithoutExtension = event.file.name.replace(/\.\w+$/, "");
        const typeNum = getFileType(event.file.name.split('.').pop().toLowerCase())
        const tempFileId = Date.now()//时间戳是13位
        const temp_file = {
            FileName: nameWithoutExtension,
            FileType: typeNum,
            FileId: tempFileId,
            temp: true,
            progress: 0,
        }
        row.files.push(temp_file)
        //找到临时上传文件索引
        i = row.files.findIndex(file => file.FileId === tempFileId);
        if (i == -1) return
        response = await handleFileChange(event.file, row.files, i)
        if (!response) {
            new proxy.$tips('上传失败', "error").Mess_age()
            return
        }
    }

    const urldata = new URLSearchParams();
    urldata.append('Token', getCookie('token'));
    urldata.append('Account', user.emailname);
    urldata.append('SubmissionId', row._id);
    urldata.append('TaskId', items._id);
    urldata.append('FileInfos', filelist);
    urldata.append('Comments', row.Comments);
    const res = await new proxy.$request(proxy.$urls.m().ModifySubmission, urldata).modepost();
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        button_loading.value = false;
        return;

    }
    else {
        if (res.data && res.data.Error == 0) {

            if (i != null) {
                //上传
                row.files[i].FileId = response.FileShareInfo._Id;
                row.files[i].FileSize = response.FileShareInfo.FileSize;
                row.files[i].FileName = response.FileShareInfo.FileName;
                row.files[i].FileType = response.FileShareInfo.FileType;
                row.files[i].filtPath = response.FileShareInfo.filtPath;
                row.files[i].FolderId = 0;
                row.files[i].temp = false;
            }
            else {
                //删除
                const task = renwuinfo.arr.find((i) => i._id === items._id);
                if (task) {
                    const submissionIndex = task.Submissioninfo.findIndex((i) => i._id === row._id);
                    if (submissionIndex !== -1) {
                        task.Submissioninfo[submissionIndex] = res.data.submissioninfo
                            ;
                    }
                }
            }

            // eventBus.emit('taskrefresh');
            new proxy.$tips('保存成功', "success").Mess_age()
            editrenwu.value = false;
            fileList_edit.value = []
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()
        }
    }
}
// const restoreActivename=()=>{
//     activeNames.value=JSON.parse(sessionStorage.getItem('activeCollapseItems'))
// }
const previewFile = (e) => {
    if (e.FileType == 7 || e.FileType == 8 || e.FileType == 9 || e.FileType == 15) {
        preview({ images: e.filtPath });
    }
    else if (e.FileType == 11) {
        previewdialog.value = true;
        // videoUrl.value = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(e.FilePath)
        videoUrl.value = e.filtPath
    }
    else {
        const file = {
            FileType: e.FileType,
            FileName: e.FileName,
            OssBucketPath: e.filtPath
        }
        navigateBasedOnFileType(file)
    }
}
const filelist = [];//文件id
const handleFileChange = async (file, item = null, fileIndex = null) => {
    let client = null;
    if (item == null && fileIndex == null) {
        client = new Client()
    }
    else {
        client = new Client(file.name,
            (fileName, percent) => updateFileProgress(fileName, percent, item, fileIndex));
    }
    let credentials = null;
    //判断凭证是否过期
    if (client.isCredentialsExpired()) {
        const urldata = new URLSearchParams();
        urldata.append('Account', user.emailname);
        urldata.append('Token', getCookie('token'));
        urldata.append('ProjectId', projectinfo._id);
        const res = await new proxy.$request(proxy.$urls.m().GetSTS, urldata).modepost();
        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            return;
        }
        else {
            if (res.data && res.data.Error == 0) {
                credentials = res.data;
            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age()
            }
        }
    }
    const nameWithoutExtension = file.name.replace(/\.\w+$/, "");
    client.file = file;
    client.credentials = credentials
    // const timestamp = Date.now(); // 获取当前时间戳
    const filename_hashcode = await getHash(file)
    const extara = projectinfo._id + "/" + 0 + "/" + filename_hashcode;
    client.extara = extara;
    await client.createClient();
    let ext = file.name.substring(file.name.lastIndexOf('.'));
    const url = filepath + extara + ext;
    let id = null;
    try {
        const file_data = new URLSearchParams();
        file_data.append('Token', getCookie('token'));
        file_data.append('ProjectId', projectinfo._id);
        file_data.append('Account', user.emailname);
        file_data.append('FileName', nameWithoutExtension);
        file_data.append('fileType', getFileType(file.name.split('.').pop().toLowerCase()));
        file_data.append('FolderId', 0);
        file_data.append('FileSize', file.size);
        file_data.append('OSSPath', url);
        let res = null;
        res = await new proxy.$request(proxy.$urls.m().UploadFile, file_data).modepost();
        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age();
        }
        else {
            if (res.data && res.data.Error == 0 && res.data.FileShareInfo) {
                id = res.data.FileShareInfo._id;
                filelist.push(id);
                return res.data
            }
            else if (res.data.Error != 0) {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age();
            }

        }
    }
    catch (e) {
        new proxy.$tips("上传失败", "error").Mess_age();
    }

};
const updateFileProgress = (fileName, percent, item, i) => {
    item[i].progress = percent;
}
const finishhandleClick = (row, item) => {
    ElMessageBox.confirm(
        '此操作一经提交将无法恢复，是否继续？',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            await checkStatus(row, item, 1)

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消删除'
            });
        });
}
const rejectHandleClick = (row, item) => {
    ElMessageBox.prompt('请输入驳回理由', '驳回', {
        confirmButtonText: '确认驳回',
        cancelButtonText: '取消',
        inputPattern: /^(?!.*[<>]).+$/,
        inputErrorMessage: '理由不能为空',
    })
        .then(async ({ value }) => {
            await checkStatus(row, item, 2, value)
        })
        .catch(error => {
            new proxy.$tips("已取消", "info").Mess_age();
        });

}
const checkStatus = async (row, item, SubmissionStatus, rejectValue = null) => {
    const urldata = new URLSearchParams();
    urldata.append('Token', getCookie('token'));
    urldata.append('Account', user.emailname);
    urldata.append('ProjectId', projectinfo._id);
    urldata.append('TaskId', item._id);
    urldata.append('Status', SubmissionStatus);
    if (rejectValue != null) {
        urldata.append('ModificationSuggestions', rejectValue);
    }
    urldata.append('SubmissionId', row._id);
    const res = await new proxy.$request(proxy.$urls.m().CheckAssigment, urldata).modepost();

    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            new proxy.$tips('保存成功', "success").Mess_age()
            // eventBus.emit('taskrefresh');
            const task = renwuinfo.arr.find((i) => i._id === item._id);
            if (task) {
                const submissionIndex = task.Submissioninfo.findIndex((i) => i._id === row._id);
                if (submissionIndex !== -1) {
                    task.Submissioninfo[submissionIndex] = res.data.submissioninfo
                        ;
                }
            }
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()
        }
    }
}
const rweditRef = ref(null)
const fileList_edit = ref([])
const rules = reactive({
    describe: [
        { required: true, message: '请输入任务描述', trigger: 'blur' },
    ],
    jzdate: [
        { required: true, message: '请选择截止日期', trigger: 'blur' },
    ],
})
const rwform = reactive(
    {
        describe: "",
        chengyuan: [],
        fileList_load: [],
        jzdate: "",
        creatorName: "",
    }
)
let assignment = null;
const editrenwuplus = (e, item) => {
    e.stopPropagation();
    assignment = item;
    editrenwu.value = true
}
const removeFile = (index) => {

    rwform.fileList_load = rwform.fileList_load.filter(file => file.FileId != index);

}
const deleterenwuplus = (e, id) => {
    e.stopPropagation();
    ElMessageBox.confirm(
        '此操作一经提交将无法恢复，是否继续？',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            const urldata = new URLSearchParams();
            urldata.append('Token', getCookie('token'));
            urldata.append('Account', user.emailname);
            urldata.append('ProjectId', projectinfo._id);
            urldata.append('TaskId', id);
            const res = await new proxy.$request(proxy.$urls.m().DeleteAssigment, urldata).modepost();
            if (!res) return;
            if (res.status != 200) {
                new proxy.$tips(res.data.message, "warning").Mess_age()
                return;
            }
            else {
                if (res.data && res.data.Error == 0) {
                    eventBus.emit('taskrefresh');
                    // const taskindex = renwuinfo.arr.findIndex((i) => i._id === id);
                    // renwuinfo.arr.splice(taskindex, 1);
                    new proxy.$tips('任务已删除', "success").Mess_age()
                }
                else {
                    const errorMessage = handleError(res);
                    new proxy.$tips(errorMessage, "error").Mess_age()
                }
            }

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消删除'
            });
        });

}
const title = computed(() => {
    return store.state.title
})
const editrenwuclick = async () => {
    await rweditRef.value?.validate(async (valid) => {
        if (!valid) {
            ElMessage.error("请完善信息，再提交！");
            return false;
        }
        button_loading.value = true;
        filelist.length = 0;
        //未上传的文件获取文件id
        for (const file of fileList_edit.value) {

            await handleFileChange(file.raw)
        }
        //已经上传的文件id
        for (const item of rwform.fileList_load) {
            filelist.push(item.FileId)
        }

        const urldata = new URLSearchParams();
        urldata.append('Token', getCookie('token'));
        urldata.append('Account', user.emailname);
        urldata.append('ProjectId', projectinfo._id);
        urldata.append('ProjectAssignmentId', assignment._id);
        urldata.append('accountinfos', rwform.chengyuan);
        urldata.append('Description', rwform.describe);
        urldata.append('deadline', Date.parse(rwform.jzdate));
        if (filelist.length != 0) {
            urldata.append('FileInfos', filelist);
        }
        urldata.append('TaskTitleId', title.value.id);
        const res = await new proxy.$request(proxy.$urls.m().ModifyAssigment, urldata).modepost();

        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            button_loading.value = false;
            return;

        }
        else {
            if (res.data && res.data.Error == 0) {
                eventBus.emit('taskrefresh');
                // const taskindex = renwuinfo.arr.findIndex((i) => i._id === assignment._id);
                // renwuinfo.arr[taskindex] = res.data.ProjectTaskinfo;
                new proxy.$tips('保存成功', "success").Mess_age()
                editrenwu.value = false;
                fileList_edit.value = []
            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age()
            }
        }
        button_loading.value = false;

    })

    //发送请求
}
const editrenwucancel = () => {
    button_loading.value = false;
    editrenwu.value = false;
    rweditRef.value.resetFields()
}
const beforeUpload = (file) => {
    let ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    //计算文件的大小
    const isLt5M = file.size / 1024 / 1024 < 1024;
    //如果大于500M
    if (!isLt5M) {
        new proxy.$tips("文件太大,请重新选择", "warning").Mess_age()
        rwform.fileList.pop();
        return false;
    }

    // 如果文件类型不在允许上传的范围内
    if (!(ext in FileType)) {
        new proxy.$tips("文件类型不支持上传，请重新选择", "warning").Mess_age();
        rwform.fileList.pop();
        return false;
    }

    // 如果文件类型支持
    return true;

}
const finishAssignment = (item) => {
    ElMessageBox.confirm(
        '此操作一经提交将无法恢复，是否继续？',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {

            const urldata = new URLSearchParams();
            urldata.append('Token', getCookie('token'));
            urldata.append('Account', user.emailname);
            urldata.append('ProjectId', projectinfo._id);
            urldata.append('ProjectAssignmentId', item._id);
            const res = await new proxy.$request(proxy.$urls.m().FinishAssigment, urldata).modepost();

            if (!res) return;
            if (res.status != 200) {
                new proxy.$tips(res.data.message, "warning").Mess_age()
                return;
            }
            else {
                if (res.data && res.data.Error == 0) {
                    eventBus.emit('taskrefresh');
                    new proxy.$tips('完成', "success").Mess_age()

                }
                else {
                    const errorMessage = handleError(res);
                    new proxy.$tips(errorMessage, "error").Mess_age()
                }
            }

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消删除'
            });
        });
}
const renwuinfo = reactive({
    arr: []
})
const rwlist = computed(() => store.state.rwlist);
const status = computed(() => store.state.status);
const temp_renwulist = reactive({
    arr: []
})
// 使用 watch 来监听 rwlist 和 status
watch([rwlist, status], ([newRwlist, newStatus]) => {
    const temp_status = Number(newStatus);

    renwuinfo.arr = newRwlist.filter(item => {
        // 定义过滤条件
        const shouldInclude = temp_status === -1
            ? item.status === true || item.status === false
            : item.status === Boolean(temp_status);

        // 只保留符合条件的 item
        return shouldInclude;
    })
    // .map(item => {
    //     // 创建一个新的对象
    //     const newItem = { ...item }; // 深拷贝 item

    //     // 如果 Submissioninfo 为空，根据 accountinfos 动态生成数据
    //     if (newItem.Submissioninfo.length === 0) {
    //         newItem.Submissioninfo = newItem.accountinfos.map(account => ({
    //             UserAccount: account,
    //             Comments: "——", // 默认评论为空
    //             SubmissionTime: null, // 默认提交时间为空
    //             fileInfos: [],// 默认文件数组为空
    //             blank: true
    //         }));
    //     }

    //     return newItem; // 返回修改后的 item
    // });
    temp_renwulist.arr = renwuinfo.arr; // 确保通过 .value 访问 ref 的值
    if (renwuinfo.arr.length > 0) {
        activeNames.value = renwuinfo.arr.filter(item => item.status == 0) // 筛选出进行中的任务
            .slice(0, 3) // 取前三个
            .map(item => item._id);
    }
});
const scrollView = (id) => {

    const item = renwuinfo.arr.find(item => item._id === id);
    if (item) {
        activeNames.value = [item._id];
        nextTick(() => {
            const collapseItem = document.querySelector(`[name="${item._id}"]`);
            if (collapseItem) {
                collapseItem.scrollIntoView({ behavior: 'smooth' });
            }
        });
    }
}
const sortlist = (flag) => {
    switch (flag) {
        case '1':
            {
                renwuinfo.arr = temp_renwulist.arr.slice().sort((a, b) => {
                    return new Date(b.createdTime) - new Date(a.createdTime);
                });
                break;
            }
        case '2':
            {
                renwuinfo.arr = temp_renwulist.arr.slice().sort((a, b) => {
                    return new Date(b.deadline) - new Date(a.deadline);
                });
                break;
            }
        case '3':
            {
                renwuinfo.arr = temp_renwulist.arr.slice()
                break;
            }
        case '4':
            {
                renwuinfo.arr = temp_renwulist.arr.slice().sort((a, b) => {
                    return (b.status === false) - (a.status === false)
                });

                break;
            }
        default:
            break;
    }
    if (renwuinfo.arr.length > 0) {
        activeNames.value = renwuinfo.arr.slice(0, 3).map(item => item._id);
    }


}
const sort_reverse = () => {
    renwuinfo.arr.reverse();
    if (renwuinfo.arr.length > 0) {
        activeNames.value = renwuinfo.arr.slice(0, 3).map(item => item._id);
    }
}
const downloadClick = (item) => {
    fetch(item.filtPath)
        .then(res => res.blob())
        .then(blob => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = item.FileName + "." + getFileTypeByValue(item.FileType).toLowerCase(); // 强制生效的文件名 
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        });
};
// const downloadClick = async (item) => {
//     new proxy.$tips("下载准备中，请稍等", "warning").Mess_age()
//     const chunkSize = 1024 * 1024;
//     const totalChunks = Math.ceil(item.FileSize / chunkSize);
//     let fileBytes = []; // 用于存储解码的二进制数据
//     let flag = true;
//     console.log(item)
//     for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
//         const file_data = new URLSearchParams();
//         file_data.append('Token', getCookie('token'));
//         file_data.append('ProjectId', projectinfo._id);
//         file_data.append('Account', user.emailname);
//         file_data.append('FileId', item.FileId);
//         // file_data.append('Fileversion', item.FileVersion);
//         file_data.append('StartPosition', chunkIndex * chunkSize);
//         await new proxy.$request(proxy.$urls.m().DownloadFile, file_data).modepost().then(res => {
//             if (res.status != 200) {
//                 new proxy.$tips(res.data.message, "warning").Mess_age()
//                 flag = false;
//                 return;
//             }
//             else {
//                 if (res.data.Error == 0 && res && res.data && res.data.FileContent && res.data.FileContent.$binary && res.data.FileContent.$binary.base64) {
//                     try {
//                         let base64Chunk = res.data.FileContent.$binary.base64;
//                         const byteCharacters = atob(base64Chunk);  // Base64 转原始字符串
//                         const byteNumbers = new Array(byteCharacters.length);
//                         for (let i = 0; i < byteCharacters.length; i++) {
//                             byteNumbers[i] = byteCharacters.charCodeAt(i);
//                         }
//                         const byteArray = new Uint8Array(byteNumbers);

//                         // 存储解码后的 Uint8Array 数据块
//                         fileBytes.push(byteArray);
//                     } catch (decodeError) {
//                         new proxy.$tips("Base64 解码失败", "error").Mess_age();
//                         flag = false;
//                         return;
//                     }
//                 }
//                 else {
//                     const errorMessage = handleError(res);
//                     new proxy.$tips(errorMessage, "error").Mess_age();
//                     flag = false;
//                     return;
//                 }
//             }
//         })

//     }
//     if (flag == false) return;
//     try {
//         const totalLength = fileBytes.reduce((acc, chunk) => acc + chunk.length, 0); // 计算总长度
//         const combinedArray = new Uint8Array(totalLength); // 创建合并后的数组
//         let offset = 0;
//         fileBytes.forEach(chunk => {
//             combinedArray.set(chunk, offset); // 从 offset 开始设置数据
//             offset += chunk.length;
//         });
//         // 将拼接后的块数组转换为 Blob
//         const blob = new Blob([combinedArray], { type: 'application/octet-stream' });
//         const link = document.createElement('a');
//         link.href = URL.createObjectURL(blob);
//         console.log(link.href)
//         link.download = item.FileName + "." + getFileTypeByValue(item.FileType).toLowerCase();
//         document.body.appendChild(link);
//         link.click(); // 触发下载
//         document.body.removeChild(link);
//     }
//     catch (error) {
//         new proxy.$tips("下载失败", "error").Mess_age()
//         console.log(error)
//     }


// }

const deleteClick = (item, file) => {
    ElMessageBox.confirm(
        '此操作将删除此文件，是否继续？',
        '警告',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }

    )
        .then(async () => {
            filelist.length = 0;
            for (const item of item.FileInfos) {
                if (file.FileId != item.FileId) {
                    filelist.push(item.FileId)
                }
            }
            const urldata = new URLSearchParams();
            urldata.append('Token', getCookie('token'));
            urldata.append('Account', user.emailname);
            urldata.append('ProjectId', projectinfo._id);
            urldata.append('ProjectAssignmentId', item._id);
            urldata.append('accountinfos', item.accountinfos);
            urldata.append('Description', item.description);
            urldata.append('deadline', item.deadline);
            urldata.append('TaskTitleId', title.value.id);
            if (filelist.length != 0) {
                urldata.append('FileInfos', filelist);
            }

            const res = await new proxy.$request(proxy.$urls.m().ModifyAssigment, urldata).modepost();

            if (!res) return;
            if (res.status != 200) {
                new proxy.$tips(res.data.message, "warning").Mess_age()
                button_loading.value = false;
                return;

            }
            else {
                if (res.data && res.data.Error == 0) {
                    eventBus.emit('taskrefresh');
                    // const taskindex = renwuinfo.arr.findIndex((i) => i._id === item._id);
                    // renwuinfo.arr[taskindex] = res.data.ProjectTaskinfo;
                    new proxy.$tips('保存成功', "success").Mess_age()
                    editrenwu.value = false;
                    fileList_edit.value = []
                }
                else {
                    const errorMessage = handleError(res);
                    new proxy.$tips(errorMessage, "error").Mess_age()
                }
            }
        })
}
const renwu_id = computed(() => store.state.renwu_id);
watchEffect(() => {
    scrollView(renwu_id.value)
})
const loadData = async () => {
    rwform.fileList_load = []
    rwform.describe = assignment.description;
    rwform.chengyuan = assignment.accountinfos;
    rwform.jzdate = formatDate(assignment.deadline);

    rwform.fileList_load = assignment.FileInfos.map(item => ({
        FileId: item.FileId,
        FileName: item.FileName,
        FileType: item.FileType
    }));
    rwform.creatorName = assignment.creatorName;
    const urldata = new URLSearchParams();
    urldata.append('Account', user.emailname);
    urldata.append('Token', getCookie('token'));
    urldata.append('ProjectId', projectinfo._id);
    const res = await new proxy.$request(proxy.$urls.m().GetUser, urldata).modepost();
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            xmcyoptions.length = 0;
            res.data.Permissions.forEach(user => {
                xmcyoptions.push({
                    label: user.AcountName,
                    value: user.AcountName
                });
            });

        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }



    }
}
const isUserInAccountInfos = (item) => {

    return item.creatorName == user.emailname && sessionStorage.getItem("flag") != 2;
};
const handleCheckAll = (val) => {
    indeterminate.value = false
    if (val) {
        rwform.chengyuan = xmcyoptions.map((_) => _.value)

    } else {
        rwform.chengyuan = []

    }

}
// watchEffect(() => {
//     renwuinfo.arr = rwlist.value.filter(item =>
//         status === -1
//             ? item.FinishState === 0 || item.FinishState === 1
//             : item.FinishState === status
//     );
// });
const replyrenwuclick = async () => {
    if (fileList_reply.value.length == 0 && replyform.describe == "") {
        new proxy.$tips("请输入文字回复或上传文件", "warning").Mess_age()
        return
    }
    filelist.length = 0;
    button_loading.value = true;
    for (const file of fileList_reply.value) {

        await handleFileChange(file.raw)
    }
    // console.log(rwform.fileList_load)
    // for (const item of rwform.fileList_load) {
    //     filelist.push(item.FileId)
    // }
    const taskid = sessionStorage.getItem("Taskid")
    // const item = JSON.parse(sessionStorage.getItem("item"))
    const urldata = new URLSearchParams();
    urldata.append('Account', user.emailname);
    urldata.append('Token', getCookie('token'));
    urldata.append('ProjectId', projectinfo._id);
    urldata.append('TaskId', taskid);
    urldata.append('Comments', replyform.describe);
    if (filelist.length != 0) {
        urldata.append('FileInfos', filelist);
    }

    const res = await new proxy.$request(proxy.$urls.m().SubmitAssigment, urldata).modepost();
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            // eventBus.emit('taskrefresh');
            const task = renwuinfo.arr.find((i) => i._id == taskid);
            if (task) {
                task.Submissioninfo.push(res.data.submissioninfo);
                // AddUserItem(task, true);
            }
            new proxy.$tips("回复成功", "success").Mess_age()
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }



    }
    button_loading.value = false;
    replycontent.value = false;
}
const replyrenwucancel = () => {
    replycontent.value = false;
    // replyRef.value.resetFields()
}
const handleClickreply = (Taskcode) => {
    //回复
    replycontent.value = true;
    sessionStorage.setItem("Taskid", Taskcode)
    // sessionStorage.setItem("item", JSON.stringify(row))
}
const getHash = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}
</script>